import {useLocation} from 'react-router-dom';
export default function Overview({data,tokenId}) {
  const location = useLocation();
  const qs=require('qs')
  const query = qs.parse(location.search, {ignoreQueryPrefix: true});
  let account = query.a ;
  const a=window.location.href
  const href=a.substring(a.indexOf('?')+1)
  // 通证类型：1-erc20 2-erc721,3-其他
  return (
    <div className="card h-100">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h2 className="card-header-title">
          Overview
          <span className="u-label u-label--xs u-label--secondary rounded ml-1" 
                data-toggle="tooltip" 
                data-original-title="CRC-20 or Compatible token type">
            {data.token_types === 1 ? <b>CRC-20</b> : undefined}
            {data.token_types === 2 ? <b>CRC-721</b> : undefined}
            {data.token_types === 4 ? <b>CRC-1155</b> : undefined}
          </span>
          <span className="u-label u-label--xs u-label--badge-out u-label--secondary ml-1" 
                style={{letterSpacing: 0}} 
                data-toggle="tooltip" 
                data-original-title="Caduceus Cross-Chain Token Type">
            {data.token_types}
          </span>
        </h2>
      </div>

      <div className="card-body">
        <div className="border-bottom pb-1 mb-3" style={{marginTop: '-6px'}}>
          <div className="row mx-gutters-md-1">
            <div className="col-6">
              <div className="small text-uppercase text-muted">
                <span data-toggle="tooltip" title="" data-original-title="Price per Token">
                  Price
                </span>
              </div>
              <span className="d-block">
                ${data.price_dollar}
                <span className="small text-secondary text-nowrap"> @ {data.price_ht} CMP</span>
              </span>
            </div>
            <div className="col-6 u-ver-divider u-ver-divider--left">
              <div className="small text-uppercase text-muted">
                <span>Market Cap </span>
                <i
                  className="far fa-question-circle text-muted"
                  data-toggle="tooltip"
                  data-html="true"
                  data-title="<p className='text-white text-left mb-0'>Calculated by multiplying the token Supply with the current market price per token</p>"
                  data-original-title=""
                  title=""
                ></i>
              </div>
              <span className="d-block">
                <span
                  className="u-label u-label--sm u-label--value u-label--text-dark u-label--secondary rounded"
                  data-toggle="tooltip"
                  data-placement="auto"
                  data-html="true"
                  title=""
                  data-original-title=""
                >
                  ${data.market_cap}
                </span>
              </span>
            </div>
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-md-4 mb-1 mb-md-0">
            <span data-toggle="tooltip" title="" style={{whiteSpace: 'nowrap'}} data-original-title="Total Supply">
              Total Supply:
            </span>
          </div>
          <div className="col-md-8 font-weight-medium">
            <span className="hash-tag text-truncate mr-2" data-toggle="tooltip" title="" data-original-title={data.total_supply}>
              {data.total_supply}
            </span>
            <b>{data.token_symbol}</b>
            <span className="text-secondary">
              (
              <span data-toggle="tooltip" title="" data-original-title={`Circulating Supply: ${data.circulating_supply}`}>
                CSupply: {data.circulating_supply}
              </span>
              )
              <input name="ctl00$ContentPlaceHolder1$hdnSymbol" type="hidden" value="HOT" />
              <input name="ctl00$ContentPlaceHolder1$hdnTotalSupply" type="hidden" value={data.total_supply} />
            </span>
          </div>
        </div>

        <div>
          <hr className="hr-space" />
          {
              data.token_types === 2 && account != undefined && href.length < 10   ? (
              
              <div className="row align-items-center">
                <div className="col-md-4 mb-1 mb-md-0">TokenId:</div>
                {/* <div className="col-md-8">{parseInt(TokenId,16) }</div> */}
                <div className="col-md-8">{account}</div>
              </div>
            ) : (
              <div className="row align-items-center">
                <div className="col-md-4 mb-1 mb-md-0">Holders:</div>
                <div className="col-md-8">{data.holders} addresses</div>
              </div>
            )
          }
        </div>

        <div>
          <hr className="hr-space" />
          {
              data.token_types === 2 && account != undefined  && href.length < 10  ? (
              <div className="row align-items-center">
                <div className="col-md-4 mb-1 mb-md-0">Holder:</div>
                <div className="col-md-8">
                  <span>{tokenId.holder_address}</span>
                </div>
                <hr className="hr-space" />
              </div>
            ) : (
              <div className="row align-items-center">
                <div className="col-md-4 mb-1 mb-md-0">Transfers:</div>
                <div className="col-md-8">
                  <span>{data.transfers}</span>
                </div>
                <hr className="hr-space" />
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}
