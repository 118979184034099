export default function MySideBar({ value, user }) {
  return (
    <div className="col-md-3">
      <div className="list-group list-group-lg mb-3">
        <a href="myaccount" className={`list-group-item list-group-item-action ${value === 'myaccount' ? 'active' : ''}`}>
          <i className="fa fa-user-circle mr-1"></i> My Profile
        </a>
        <a href="myaddress" className={`list-group-item list-group-item-action ${value === 'myaddress' ? 'active' : ''}`}>
          <i className="fa fa-heart mr-1"></i> Watch List
        </a>
        <a href="mynotes_tx" className={`list-group-item list-group-item-action ${value === 'mynotes_tx' ? 'active' : ''}`}>
          <i className="far fa-sticky-note mr-1"></i> Txn Private Notes
        </a>
        <a href="mynotes_address" className={`list-group-item list-group-item-action ${value === 'mynotes_address' ? 'active' : ''}`}>
          <i className="far fa-sticky-note mr-1"></i> Private Name Tags
        </a>
        <a href="mytokenignore" className={`list-group-item list-group-item-action ${value === 'mytokenignore' ? 'active' : ''}`}>
          <i className="fas fa-eye-slash"></i> Token Ignore List
        </a>
        <a href="myapikey" className={`list-group-item list-group-item-action ${value === 'myapikey' ? 'active' : ''}`}>
          <i className="fa fa-key mr-1"></i> API-KEYs
        </a>
        <a href="verifiedAddresses" className={`list-group-item list-group-item-action ${value === 'verifiedAddresses' ? 'active' : ''}`}>
          <i className="fas fa-badge-check mr-1"></i> Verified Addresses
        </a>
        <a href="mycustomabi" className={`list-group-item list-group-item-action ${value === 'mycustomabi' ? 'active' : ''}`}>
          <i className="fa fa-tasks mr-1"></i> Custom ABIs
        </a>
        {(user && user.is_admin) ? (<a href="audit" className={`list-group-item list-group-item-action ${value === 'audit' ? 'active' : ''}`}>
          <i className="fa fa-user mr-1"></i> Audit
        </a>) : ''}
      </div>
      {value != 'audit' && <div className="card mb-3">
        <div className="card-body">
          <div className="media">
            <span className="btn btn-sm btn-icon btn-soft-info rounded-circle mb-2 mr-2">
              <span className="fas fa-info btn-icon__inner"></span>
            </span>
            <div className="media">
              <h2 className="h5">
                {value === 'myaccount' && 'Account Settings?'}
                {value === 'myaddress' && 'Watch List?'}
                {value === 'mynotes_tx' && 'Txn Notes?'}
                {value === 'mynotes_address' && 'Address Notes?'}
                {value === 'mytokenignore' && 'Token Ignore List?'}
                {value === 'myapikey' && 'Api-Key?'}
                {value === 'verifiedAddresses' && 'Verified Addresses'}
                {value === 'mycustomabi' && 'Contract Custom ABI'}
              </h2>
            </div>
          </div>
          <p className="mb-0">
            {value === 'myaccount' && 'This page provides an overview of your cmpscan account. You can also update your email address or password here.'}
            {value === 'myaddress' && 'An Email notification can be sent to you when an address on your watch list receives an incoming CMP transaction. The address watch list can also be useful for portfolio tracking.'}
            {value === 'mynotes_tx' && 'A private transaction note (up to 100 characters) can be saved and is useful for transaction tracking.'}
            {value === 'mynotes_address' && 'A private name tag (up to 35 chars) and memo (up to 500 chars) for individual addresses can be saved and is useful for labelling addresses of interest.'}
            {value === 'mytokenignore' && "Don't like seeing a Token? Now you can hide it from being displayed at the Address Token Summary, Token Holdings and Watch list pages."}
            {value === 'myapikey' && 'For developers interested in building applications using our API Service, please create an Api-Key Token which you can then use with all your api requests.'}
            {value === 'verifiedAddresses' && 'Addresses that you have verified and claimed ownership.'}
            {value === 'mycustomabi' && 'You can add custom ABI that used to interact with smart contract when login to your cmpscan account.'}
            <br />
            <br />
            {(value === 'mynotes_tx' || value === 'mynotes_address') && 'This information is only visible to you.'}
            {value === 'mynotes_address' && 'This information is only visible to you.'}
            {value === 'mytokenignore' && 'This is a private setting only applicable to you when logged-in with an cmpscan account.'}
            <br />
          </p>
        </div>
      </div>}
    </div>
  );
}
