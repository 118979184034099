import { useState, useContext, useEffect } from 'react';
import { useRequest } from 'ahooks';
import { message, Input, Tooltip } from 'antd'
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';

import MySideBar from '../../components/MySideBar';
import UserContext from '../../UserContext';
import Pager from '../../components/Pager';
import '../MyNotesAddress/index.css'

const { Search } = Input;

function EditApiKeyModal({ data = {}, user, show, onHide }) {
  const [fields, setFields] = useState({
    contract_address: data.contract_address || '',
    contract_creator: data.contract_creator || '',
    signature: data.signature || ''
  });

  const verifyAdsRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/verifiedAddress/verify',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );

  const keyDeleteRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/apiKey/keyDelete',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );

  const handleEdit = () => {
    if (!fields.api_key_name) {
      return;
    }

    verifyAdsRequest.run(fields).then(res => {
      if (res.code === 0) {
        onHide();
      } else {
      }
    });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className="p-2">
        <Modal.Title>APIKey Options</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form method="post" className="mx-md-auto">
          <div className="form-group row">
            <label className="col-sm-3" htmlFor="Address">
              Contract Address
            </label>
            <div className="col-sm-9">
              <strong>{data.contract_address}</strong>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3" htmlFor="Description">
              AppName
            </label>
            <div className="input-group input-group-sm col-sm-9">
              <input
                type="text"
                value={fields.api_key_name}
                className="form-control"
                onChange={e => {
                  setFields({ ...fields, api_key_name: e.target.value });
                }}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-sm-flex justify-content-between" style={{ width: '100%' }}>
          <div className="d-flex">
            <div>
              <input type="button" value="Save Changes" className="btn btn-primary btn-xs" onClick={handleEdit} />
            </div>
            <div className="px-2">
              <input type="button" value="Return" className="btn btn-secondary btn-xs" onClick={onHide} />
            </div>
          </div>
          <div className="ml-auto">
            <a
              href="#"
              className="btn btn-danger btn-xs"
              onClick={e => {
                e.preventDefault();
                keyDeleteRequest.run({ ...fields }).then(res => {
                  if (res.code == 0) {
                    onHide()
                  } else {
                  }
                });
              }}
            >
              <i className="far fa-trash-alt" data-toggle="tooltip" title="" data-original-title="Unlink Address from Watch List"></i>&nbsp; Remove
            </a>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

function VerifiedAdsList({ user, update, filter }) {
  const [curVerifiedAds, setCurVerifiedAds] = useState();
  const [showEditModal, setShowEditModal] = useState(false);

  let state = {
    start: '1',
    length: '50',
    filter: filter || ''
  }

  const listVerifiedAdsRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/verifiedAddress/addressList',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true },
  );

  useEffect(() => {
    listVerifiedAdsRequest.run(state);
  }, [update, filter]);

  const handleEditHide = () => {
    listVerifiedAdsRequest.run(state);
    setShowEditModal(false);
  };

  const data = listVerifiedAdsRequest.data?.verified_address_list || [];
  const counts = listVerifiedAdsRequest.data?.counts || 0;
  const limit_counts = listVerifiedAdsRequest.data?.limit_counts || 0;
  const totalPage = Math.ceil(Number(counts) / state.length);

  console.log(data, 'datadata');

  return (
    <div className="card-body mb-4">
      {/* <Alert show={delStatus} variant='success'>
        Status: APIKey Removed
      </Alert> */}
      <div className="d-md-flex justify-content-between align-items-center mb-4">
        <p className="mb-2 mb-lg-0">
          {counts} used (Out of {limit_counts} max quota)
        </p>
        <nav className="mb-4 mb-md-0" aria-label="page navigation">
          <div>
            <Pager current={state.start} total={totalPage} path="/txs" />
          </div>
        </nav>
      </div>
      <div className="table-responsive mb-2 mb-md-0">
        <div className="mx-md-auto">
          <figure className="mb-7 ie-data-report">
            <table className="table">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Address</th>
                  <th scope="col">Quick Links</th>
                  <th scope="col" data-toggle="tooltip" title="" data-original-title="UTC TimeZone (yyyy-mm-dd)">
                    Verified Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(data) && data.length !== 0 ? (
                  data.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="align-top">
                          <a href={`/token/${item.contract_address}`}>
                            <span className="text-primary">{item.contract_address}</span>
                          </a>
                        </td>
                        <td className="align-top text-nowrap">
                          <Tooltip title="Update Token Information">
                            <a
                              href={`/tokenupdate/${item.contract_address}/verified`}
                              className='btn btn-xs btn-icon btn-soft-secondary mr-1'
                            >
                              <i className='fas fa-pencil-alt btn-icon__inner' />
                            </a>
                          </Tooltip>
                          <Tooltip title="Update Token Sales Info">
                            <a
                              href={`/tokenupdate/${item.contract_address}/verified`}
                              className='btn btn-xs btn-icon btn-soft-secondary mr-1'
                            >
                              <i className='fas fa-usd-square btn-icon__inner' />
                            </a>
                          </Tooltip>
                          <Tooltip title="Add Name Tag">
                            <a
                              href={`/contactus/${item.contract_address}/verified`}
                              className='btn btn-xs btn-icon btn-soft-secondary mr-1'
                            >
                              <i className='fas fa-user-tag btn-icon__inner' />
                            </a>
                          </Tooltip>
                          <Tooltip title="Add Project Label (s)">
                            <a
                              href={`/contactus/${item.contract_address}/verified`}
                              className='btn btn-xs btn-icon btn-soft-secondary mr-1'
                            >
                              <i className='fas fa-tags btn-icon__inner' />
                            </a>
                          </Tooltip>
                          {/* <a
                            className="btn btn-outline-secondary btn-xss mr-1"
                            href="#"
                            data-toggle="tooltip"
                            title=""
                            data-original-title="Modify API Key"
                            onClick={e => {
                              e.preventDefault();
                              setCurVerifiedAds(item);
                              setShowEditModal(true);
                            }}
                          >
                            Edit
                          </a> */}
                        </td>

                        <td className="align-top">{moment(item.verified_time).format('YYYY-MM-DD')}</td>
                      </tr>
                    );
                  })
                ) : listVerifiedAdsRequest.loading ? (
                  <tr />
                ) : (
                  <tr className='mb-4'>
                    <td colSpan="3" className="align-top">
                      <center>
                        <div className="alert alert-secondary mt-4" role="alert">
                          <i className="fal fa-info-circle"></i> <span className="text-dark">You have yet to verify any address.</span>
                        </div>
                      </center>
                    </td>
                  </tr>
                )}
                <tr>
                  <td colSpan="3" className="align-top border-0">
                    <div className="alert alert-info" role="alert">
                      <p className='text-wrap'>Verify Address Ownership process involves verifying the ownership of a CMP address used to create a CMP smart contract, and this verification will link to a CMPScan account.</p>
                      <p className='text-wrap'>Once a user has claim ownership of an address or contract address, the user will be able to update their token information, dapp page and address name tag without needing to sign a new message for future submission. </p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </figure>
        </div>
      </div>
      <EditApiKeyModal user={user} data={curVerifiedAds} show={showEditModal} onHide={handleEditHide} />
    </div>
  );
}

export default function VerifiedAddresses() {
  const userContext = useContext(UserContext);
  const user = userContext.user || {};
  const [show, setShow] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const [isFlag, setIsFlag] = useState(false);

  if (user.token == 'undefined') {
    window.location.href = '/login'
  }

  return (
    <main role="main">
      <div className="container space-1">
        <div className="row">
          <MySideBar value="verifiedAddresses" user={user} />
          <div className="col-md-9">
            <div className="card">
              <div className="card-header d-md-flex justify-content-between align-items-center">
                <div className="d-flex flex-wrap">
                  <div className="mr-2 mb-2 mb-md-0">
                    <h2 className="card-header-title mt-1">My Verified Addresses</h2>
                  </div>
                  <div className="mr-2 mb-2 mb-md-0">
                    <div className="position-relative order-1 order-md-2">
                      <a
                        href={`/verifyAddress/add`}
                        id="ContentPlaceHolder1_addnew"
                        data-toggle="modal"
                        data-target="#myModal"
                        rel="tooltip"
                        title=""
                        className="btn btn-xss btn-primary rounded"
                        data-original-title="Create a new API-KEY token"
                      // onClick={() => {
                      //   setShow(!show);
                      // }}
                      >
                        <i className="fa fa-plus-square fa-lg mr-1" style={{ marginLeft: '-3px', marginBottom: '3px' }}></i>Add
                      </a>
                    </div>
                  </div>
                </div>
                <div className="d-flex ml-auto">
                  {searchStr && <div className="btn-group order-2 order-md-1 ml-2 mr-md-2" role="group">
                    <span className='btn btn-xss btn-soft-secondary ml-auto'>Filtered by
                      <span className='hash-tag hash-tag text-truncate font-weight-bold ml-2'>{searchStr}</span>
                    </span>
                    <a className="btn btn-sm btn-icon btn-soft-secondary" href="javascript:;"
                      onClick={() => setSearchStr('')}
                    >
                      <i className='fa fa-times btn-icon__inner' />
                    </a>
                  </div>}
                  <div className="position-relative order-1 order-md-2">
                    <a
                      className="btn btn-sm btn-icon btn-primary"
                      href="javascript:;"
                      onClick={() => setIsFlag(!isFlag)}
                    >
                      <i className="fa fa-search btn-icon__inner"></i>
                    </a>
                    {isFlag && <div className="searchBox">
                      <Search
                        placeholder="Search Address Private"
                        allowClear
                        enterButton="Find"
                        size="large"
                        onSearch={(value) => {
                          setSearchStr(value)
                          setIsFlag(!isFlag)
                        }}
                      />
                    </div>}
                  </div>
                </div>
              </div>
              <VerifiedAdsList user={user} update={show} filter={searchStr} />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
