import { useState, useContext, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Button, Form, Input, message, Select, Checkbox } from 'antd';
import { LeftOutlined, SnippetsOutlined, MediumOutlined, GithubOutlined, AntCloudOutlined, DingtalkOutlined, SlackOutlined, WechatOutlined, FacebookOutlined, LinkedinOutlined, TwitterOutlined, RedditOutlined, BoldOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import Modal from 'react-bootstrap/Modal';
import UserContext from '../../UserContext';

import './index.css'
const { Option } = Select;

function RejectCon({ data = {}, user, show, onHide }) {
    const auditTokenRequest = useRequest(
        body => ({
            url: '/blockBrowser/audit/tokenInfo/audit',
            method: 'post',
            headers: {
                Authorization: user.token,
            },
            body: JSON.stringify(body),
        }),
        { manual: true, formatResult: r => r },
    );
    const handleCurReject = (value) => {
        auditTokenRequest.run({ id: data.id, verified: 2, reject_reason: value.reject_reason }).then(res => {
            if (res.code == 0) {
                onHide()
            } else {
                message.error(res.enMsg)
            }
        })
    };
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton className="p-2">
                <Modal.Title>Reason for rejection</Modal.Title>
            </Modal.Header>
            <Form onFinish={handleCurReject}>
                <Modal.Body>
                    <Form.Item name="reject_reason" rules={[{ required: true, message: 'Please input your rejection!' }]}>
                        <Input.TextArea />
                    </Form.Item>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'center' }}>
                    <Form.Item>
                        <Button htmlType="button" onClick={onHide} className='mr-3'>Cancel</Button>
                        <Button type="primary" htmlType="submit">Reject</Button>
                    </Form.Item>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default function TokenUpdate() {
    const userContext = useContext(UserContext);
    const user = userContext.user || {};
    const [form] = Form.useForm();
    const { token, type } = useParams();
    const [obj, setObj] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [isReject, setIsReject] = useState(false);

    const auditTokenInfo = useRequest(
        body => ({
            url: '/blockBrowser/audit/tokenInfo/info',
            method: 'post',
            headers: {
                Authorization: user.token,
            },
            body: JSON.stringify(body),
        }),
        { manual: true, formatResult: r => r },
    );

    useEffect(() => {
        if (type == 'audit') {
            auditTokenInfo.run({ id: token }).then(res => {
                setObj(res.data)
                console.log(res.data, 'data');
                form.setFieldsValue({ ...res.data })
            })
            setIsModalOpen(false)
        }
    }, []);

    const tokensUpdate = useRequest(
        body => ({
            url: '/blockBrowser/tokens/tokenUpdate',
            method: 'post',
            headers: {
                Authorization: user.token,
            },
            body: JSON.stringify(body),
        }),
        { manual: true, formatResult: r => r },
    );

    const onFinish = (values) => {
        console.log(values, 'values');
        tokensUpdate.run({ ...values }).then(res => {
            if (res.code !== 0) {
                message.error(res.enMsg)
            } else {
                message.success(res.enMsg)
            }
        })

    };

    const onReset = () => {
        form.resetFields();
    }

    const auditTokenRequest = useRequest(
        body => ({
            url: '/blockBrowser/audit/tokenInfo/audit',
            method: 'post',
            headers: {
                Authorization: user.token,
            },
            body: JSON.stringify(body),
        }),
        { manual: true, formatResult: r => r },
    );

    const handleApprove = () => {
        auditTokenRequest.run({ id: obj.id, verified: 1 }).then(res => {
            if (res.code == 0) {
                message.success(res.enMsg)
            } else {
                message.error(res.enMsg)
            }
        })
    }

    return (
        <main id='content' role="main">
            <div className="container space-2">
                <div className="d-flex justify-content-center align-items-center mb-4" style={{ position: 'relative' }}>
                    {type == 'audit' && <a href="javascript:history.back(-1)"><LeftOutlined style={{ fontSize: '24px', height: '25px', position: 'absolute', left: 0 }} /></a>}
                    <h2 className="h3 mb-1">Token Update Application Form</h2>
                </div>
                <div className="card">
                    <div className="card-body p-4">
                        <Form layout="vertical" onFinish={onFinish} form={form} disabled={type == 'audit'}>
                            <div id="ContentPlaceHolder1_tokenupdate2div">
                                <div className="card card-pop-frame p-5-alt mb-4">
                                    <div className="row mx-gutters-md-1">
                                        <div className="col-md-5 mb-4 mb-md-0">
                                            <div className="sticky-top">
                                                <h2 className='h5 font-weight-bold'>Request Type</h2>
                                                <p>Please specify the category of your token update application.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="js-form-message form-group mb-4">
                                                <Form.Item name={['request_type', 'type']} label="Choose on " rules={[{ required: true, message: 'Please select' }]}>
                                                    <Select placeholder="Please select" allowClear>
                                                        <Option value={Number("1")}>New/First Time Token Update</Option>
                                                        <Option value={Number("2")}>Existing Token Info Update</Option>
                                                        <Option value={Number("3")}>Token/Contract Migration</Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div className="js-form-message form-group">
                                                <Form.Item name={['request_type', 'message']} label="Comment/Message">
                                                    <Input.TextArea maxLength={5000} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card card-pop-frame p-5-alt mb-4">
                                    <div className="row mx-gutters-md-1">
                                        <div className="col-md-5 mb-4 mb-md-0">
                                            <div className="sticky-top">
                                                <h2 className='h5 font-weight-bold'>Basic Information</h2>
                                                <p>Kindly check that all the links provided are working and are safe to visit before submitting.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="js-form-message form-group mb-4">
                                                <Form.Item
                                                    label="Token Contract Address"
                                                    name={['basic_information', 'contract_address']}
                                                    initialValue={token}
                                                    rules={[{ required: true, message: 'Contract address is required!' }]}
                                                >
                                                    <Input maxLength={42} />
                                                </Form.Item>
                                            </div>
                                            <div className="js-form-message form-group mb-4">
                                                <Form.Item
                                                    label="Requester Name"
                                                    name={['basic_information', 'requester_name']}
                                                    rules={[{ required: true, message: 'Requester Name is required!' }]}
                                                >
                                                    <Input maxLength={300} />
                                                </Form.Item>
                                            </div>
                                            <div className="js-form-message form-group mb-4">
                                                <Form.Item
                                                    label="Requester Email Address"
                                                    name={['basic_information', 'requester_email']}
                                                    rules={[{ type: 'email', required: true, message: 'Please enter a valid email address' }]}
                                                    extra="Please make sure that you provide an email account of the project's official domain. If the contact email is using public domains (eg. Gmail) do publish on the website for acknowledgement/verification."
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="js-form-message form-group mb-4">
                                                        <Form.Item
                                                            label="Project Name"
                                                            name={['basic_information', 'project_name']}
                                                            rules={[{ required: true, message: 'Please enter a project name' }]}
                                                        >
                                                            <Input maxLength={300} />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="js-form-message form-group mb-4">
                                                        <Form.Item
                                                            label="Official Project Website"
                                                            name={['basic_information', 'official_website']}
                                                            rules={[{ required: true, pattern: '^(http|https)://', message: 'Project Website is required' }]}
                                                        >
                                                            <Input maxLength={300} />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="js-form-message form-group mb-4">
                                                <Form.Item
                                                    label="Official Project Email Address"
                                                    name={['basic_information', 'official_email']}
                                                    rules={[{ type: 'email', required: true, message: 'Project email address is required' }]}
                                                    extra="Please make sure the email provided has the project official domain as its suffix."
                                                >
                                                    <Input maxLength={300} />
                                                </Form.Item>
                                            </div>
                                            <div className="js-form-message form-group mb-4">
                                                <Form.Item
                                                    label="Link to download a 32x32 png icon logo"
                                                    name={['basic_information', 'icon_link']}
                                                    rules={[{ required: true, message: 'Link to download a 32x32 png icon logo is required' }]}
                                                    extra="Image should be a PNG file with a resolution of 32x32"
                                                >
                                                    <Input maxLength={300} />
                                                </Form.Item>
                                            </div>
                                            <div className="js-form-message form-group mb-4">
                                                <Form.Item
                                                    label="Project Sector"
                                                    name={['basic_information', 'project_sector']}
                                                    extra="Please specify the industry/field that the project is a part of"
                                                >
                                                    <Input maxLength={300} />
                                                </Form.Item>
                                            </div>
                                            <div className="js-form-message form-group mb-4">
                                                <Form.Item
                                                    label="Project Description (Max 300 characters)"
                                                    name={['basic_information', 'project_description']}
                                                    rules={[{ required: true, message: 'Project description is required' }]}
                                                    extra="Briefly encapsulate, introduce or summarise the project's operation/mechanism/goals in a maximum of 300 characters. The short description should be written in a neutral point of view and must exclude unsubstantiated claims ('first', 'most', 'best', and etc) unless proven otherwise."
                                                >
                                                    <Input.TextArea maxLength={5000} />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card card-pop-frame p-5-alt mb-4">
                                    <div className="row mx-gutters-md-1">
                                        <div className="col-md-5 mb-4 mb-md-0">
                                            <div className="sticky-top">
                                                <h2 className='h5 font-weight-bold'>Social Profiles</h2>
                                                <p>Kindly provide us with the links to your official social media pages on these platforms. These are usually the secondary channels which users may use to contact your team.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="js-form-message mb-4">
                                                        <Form.Item name={['social_profiles', 'white_paper']} >
                                                            <Input size="large" prefix={<SnippetsOutlined />} placeholder='Whitepaper' />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="js-form-message mb-4">
                                                        <Form.Item name={['social_profiles', 'medium']} >
                                                            <Input size="large" prefix={<MediumOutlined />} placeholder='Medium' />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="js-form-message mb-4">
                                                        <Form.Item name={['social_profiles', 'github']} >
                                                            <Input size="large" prefix={<GithubOutlined />} placeholder='Github' />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="js-form-message mb-4">
                                                        <Form.Item name={['social_profiles', 'reddit']} >
                                                            <Input size="large" prefix={<AntCloudOutlined />} placeholder='Reddit' />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="js-form-message mb-4">
                                                        <Form.Item name={['social_profiles', 'telegram']} >
                                                            <Input size="large" prefix={<DingtalkOutlined />} placeholder='Telegram' />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="js-form-message mb-4">
                                                        <Form.Item name={['social_profiles', 'slack']} >
                                                            <Input size="large" prefix={<SlackOutlined />} placeholder='Slack' />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="js-form-message mb-4">
                                                        <Form.Item name={['social_profiles', 'wechat']} >
                                                            <Input size="large" prefix={<WechatOutlined />} placeholder='Wechat' />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="js-form-message mb-4">
                                                        <Form.Item name={['social_profiles', 'facebook']} >
                                                            <Input size="large" prefix={<FacebookOutlined />} placeholder='Facebook' />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="js-form-message mb-4">
                                                        <Form.Item name={['social_profiles', 'linkedin']} >
                                                            <Input size="large" prefix={<LinkedinOutlined />} placeholder='Linkedin' />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="js-form-message mb-4">
                                                        <Form.Item name={['social_profiles', 'twitter']} >
                                                            <Input size="large" prefix={<TwitterOutlined />} placeholder='Twitter' />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="js-form-message mb-4">
                                                        <Form.Item name={['social_profiles', 'discord']} >
                                                            <Input size="large" prefix={<RedditOutlined />} placeholder='Discord' />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="js-form-message mb-4">
                                                        <Form.Item name={['social_profiles', 'bitcointalk']} >
                                                            <Input size="large" prefix={<BoldOutlined />} placeholder='Bitcointalk' />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="js-form-message mb-4">
                                                        <Form.Item name={['social_profiles', 'ticketing']} >
                                                            <Input size="large" prefix={<UserSwitchOutlined />} placeholder='Ticketing' />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <div className="col-md-6"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='card card-pop-frame p-5-alt mb-4'>
                                    <div className="row mx-gutters-md-1">
                                        <div className="col-md-5 mb-4 mb-md-0">
                                            <div className="sticky-top">
                                                <h2 className='h5 font-weight-bold'>Price Data</h2>
                                                <p>Kindly take note that the price data will be obtained through these service providers. By default, price data will be provided by Coinmarketcap while exchanges with trading pairs will be provided by Coingecko. If your token is not listed on either platform, its respective values will not be shown.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="js-form-message form-group mb-4">
                                                <Form.Item
                                                    label="CoinMarketCap Ticker"
                                                    name={['price_data', 'coin_market_cap']}
                                                    rules={[{ pattern: '^(http|https)://' }]}
                                                >
                                                    <Input placeholder='https://coinmarketcap.com/currencies/cmp/' />
                                                </Form.Item>
                                            </div>
                                            <div className="js-form-message form-group mb-4">
                                                <Form.Item
                                                    label="CoinGecko Ticker"
                                                    name={['price_data', 'coingecko']}
                                                    rules={[{ pattern: '^(http|https)://' }]}
                                                >
                                                    <Input placeholder="https://www.coingecko.com/en/coins/cmp" />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='card card-pop-frame p-5-alt mb-4'>
                                    <div className="row mx-gutters-md-1">
                                        <div className="col-md-5 mb-4 mb-md-0">
                                            <div className="sticky-top">
                                                <h2 className='h5 font-weight-bold'>Others</h2>
                                                <p>Information pertaining to the project's token sales (ICO/IEO) and/or burn history. Kindly provide links/sources where required.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-7">
                                            <div className="js-form-message form-group mb-4">
                                                <Form.Item
                                                    label="Public Sale (ICO/IEO) Details (if applicable)"
                                                    name={['others', 'public_sale']}
                                                >
                                                    <Input.TextArea maxLength={5000} rows="8" />
                                                </Form.Item>
                                                {/* <textarea>
                                                    1. *Token Sale Address:
                                                    2. *Token Sale Start Date:3. *Token Sale End Date: 4. *Token Price (in USD and/or BNB):
                                                    5. Public Sale Allocation: 6. Public Sale Vesting Period: 7. IEO Launchpad: 8. Country:
                                                    9. Soft Cap / Hard Cap (If any): 10. Amount Raised: 11. Token Distribution Date:
                                                </textarea> */}
                                            </div>
                                            <div className="js-form-message form-group mb-4">
                                                <Form.Item
                                                    label="Private Sale Details (if applicable)"
                                                    name={['others', 'private_sale']}
                                                >
                                                    <Input.TextArea maxLength={5000} rows="8" />
                                                </Form.Item>
                                                {/* <textarea>
                                                    1. Private Sale Token Price (in USD and/or BNB):
                                                    2. Private Sale Allocation:
                                                    3. Private Sale Vesting Period:
                                                    4. Seed Sale Token Price (in USD and/or BNB):
                                                    5. Seed Sale Allocation:
                                                    6. Seed Sale Vesting Period:
                                                </textarea> */}
                                            </div>
                                            <div className="js-form-message form-group mb-4">
                                                <Form.Item
                                                    label="Burn Events (if applicable)"
                                                    name={['others', 'burn_event']}
                                                >
                                                    <Input.TextArea maxLength={5000} rows="8" />
                                                </Form.Item>
                                                {/* <textarea>
                                                    1. Announcements Link:
                                                    Tx Hash:
                                                    2. Announcements Link:
                                                    Tx Hash:
                                                    3. Announcements Link:
                                                    Tx Hash:
                                                </textarea> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {(type == 'profile' || type == 'verified') && (<>
                                    <div className="row my-4 px-2">
                                        <span id='ContentPlaceHolder1_lbrequiredfields4' className='denotescls'>
                                            <i></i>* denotes required fields
                                        </span>
                                    </div>
                                    <div className="bg-soft-secondary rounded p-3 mb-3">
                                        <Form.Item
                                            name="agreement"
                                            valuePropName="checked"
                                            rules={[
                                                {
                                                    validator: (_, value) =>
                                                        value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                                                },
                                            ]}
                                        >
                                            <Checkbox>
                                                By clicking 'Submit', you are agreeing that you have provided all the details and information required for the token update process. Should any details or information submitted are found to be incomplete, false or fraudulent, we reserve the right to reject your token update request and we are not obliged to disclose or explain our reasons of rejection. If your submission is aptly completed, our team will get back to you with further instructions as soon as possible. Please do not submit duplicate submissions within this period of time.
                                            </Checkbox>
                                        </Form.Item>
                                    </div>
                                    <div className="row align-items-end">
                                        <div className="col-md-6 mb-3 mb-md-0"></div>
                                        <div className="col-md-6 text-md-right">
                                            <Form.Item>
                                                <Button htmlType="button" onClick={onReset} className="mr-4">
                                                    Reset
                                                </Button>
                                                <Button type="primary" htmlType="submit">
                                                    Submit
                                                </Button>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </>)}
                            </div>
                        </Form>
                    </div>
                    {type == 'audit' && (<div className="row justify-content-center mb-4">
                        {obj.verified == 2 && <Button type="primary" danger onClick={() => setIsReject(!isReject)} className="mr-6" disabled={obj.verified == 2}>
                            Reject
                        </Button>}
                        {obj.verified == 1 && <Button type="primary" onClick={() => handleApprove()} disabled={obj.verified == 1}>
                            Approve
                        </Button>}
                        {(obj.verified == 0 || obj.verified == 3) && <>
                            <Button type="primary" danger onClick={() => setIsReject(!isReject)} className="mr-6">
                                Reject
                            </Button>
                            <Button type="primary" onClick={() => handleApprove()}>
                                Approve
                            </Button>
                        </>}
                    </div>)}
                </div>
            </div>
            <RejectCon show={isReject} data={obj} user={user} onHide={() => setIsReject(false)} />
            <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
                <Modal.Header closeButton className="p-2">
                    <Modal.Title>Notice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='mt-1 mb-3 text-secondary'>CmpScan aims to provide users with on-chain information and data extracted from the CMP blockchain in the most comprehensive and digestible way. Off-chain information such as the token information is submitted by users where CmpScan curates and updates onto the token/contract address upon verification of ownership.</p>
                    <p className='mt-1 mb-3 text-secondary'>This curation intends to provide end-users with verifiable project information about the token/contract address that they are interacting with.</p>
                    <p className='mt-1 mb-3 text-secondary'>As we receive a significant number of requests on a daily basis, we adopted a pre-filtering process that measures submissions against a number of variables and values before they are accepted.</p>
                    <p className='mt-1 mb-3 text-secondary'>Prior to sending in your submission, please check and confirm that the information submitted adheres to the items listed below. You may not receive a reply if ONE or more than one of the below is not satisfied.</p>
                    <ol className='text-secondary'>
                        <li className='mb-2'>The mandatory information (website, email and 32x32 PNG logo) are provided</li>
                        <li className="mb-2">Your email/contact email address is the same as your official website domain (i.e., youremail@yourdomain)</li>
                        <li className="mb-2">Your official website:
                            <ul>
                                <li>is accessible and safe to visit</li>
                                <li>has all its links working fine and its placeholders updated</li>
                                <li>has clear and sufficient information about your project or token</li>
                            </ul>
                        </li>
                        <li className="mb-2">The contract:
                            <ul>
                                <li>adheres to CRC-20 specifications</li>
                                <li>is valid and it is the right contract address for the project/token</li>
                            </ul>
                        </li>
                        <li className="mb-2">The project/token:
                            <ul>
                                <li>name, symbol or creative properties are not susceptible to brand infringement</li>
                                <li>is not fraudulent or misrepresentation of public entities or other projects</li>
                                <li>is using/deployed on the CMP blockchain</li>
                                <li>team members and/or founders are clearly presented with links to their supporting professional profiles (e.g. LinkedIn or equivalent)</li>
                            </ul>
                        </li>

                    </ol>
                    <p className='mt-1 mb-3 text-secondary'>Please take note that the requirements above are not a checklist that guarantees your submission to be listed and at any time we found false information or discrepancies, we reserve the rights to remove or update information.</p>
                </Modal.Body>
                <Modal.Footer>
                    <button data-dismiss="modal" className="btn btn-sm btn-secondary btn-xs cancel" type="button" causesvalidation="False" onClick={() => setIsModalOpen(false)}>
                        OK
                    </button>
                </Modal.Footer>
            </Modal>
        </main>
    )
}