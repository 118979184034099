import { useRequest } from 'ahooks';
import { useEffect, useRef, useState, useMemo } from 'react';
import moment from 'moment';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import SearchBox from '../../components/SearchBox';
import Loading from '../../components/Loading';

import './style.css'

function renderHighChart(container, data = []) {
  let bigNum = Number(data.y)
  let arr = []
  data.ResTransactionCount.map(item => {
    return arr.push(item.TradeCount)
  })
  window.Highcharts.chart(container, {
    chart: {
      spacingTop: 10,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 10,
    },

    title: {
      text: '',
      align: 'left',
    },

    xAxis: {
      title: { text: '' },
      lineWidth: 0,
      minorTickLength: 0,
      tickLength: 0,
      labels: {
        step: 7,
      },
      categories: data.ResTransactionCount.reverse().map(v => {
        const ret = moment(v.DateTime).format('MM/DD/YYYY');
        return ret;
      }),
    },

    yAxis: {
      type: 'value',
      max: bigNum,
      labels: {
        enabled: true,
      },
      gridLineWidth: 0,
      title: {
        text: null,
      }
    },

    legend: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },

    tooltip: {
      formatter: function () {
        return (
          '<span style="font-size:10px">' +
          this.key +
          '</span><br><table><tr><td style="padding:0">' +
          '<span style="color:' +
          this.series.color +
          '">Transactions: </a></span><b>' +
          this.point.y +
          '</b><br>' +
          '</td></tr></table>'
        );
      },
    },

    plotOptions: {
      series: {
        color: '#1e2022',
        enableMouseTracking: true,
        lineWidth: 1,
        shadow: false,
        animation: false,
        cursor: 'pointer',
        states: {
          hover: {
            lineWidth: 1,
          },
        },
        marker: {
          radius: 0,
        },
        point: {
          events: {
            select: function (e) {
              window.location.href = '/txs?dt=' + this.options.dt;
            },
          },
        },
      },
      column: {
        pointPadding: 0.1,
        borderWidth: 0,
      },
    },

    series: [
      {
        name: 'Transactions',
        type: 'spline',
        data: arr.reverse(),
        allowPointSelect: true,
        pointStart: 0,
      },
    ],
  });
}

function TopHead() {
  return (
    <section className="bg-img-hero-main bg-dark customBgColor" style={{ backgroundImage: 'url(/images/svg/components/bg-shape.svg?v=2)' }}>
      <div className="container space-top-2 space-bottom-3">
        <div className="row justify-content-between align-items-center mb-4">
          <div className="col-md-12 col-lg-7">
            <div className="pr-lg-4 pr-xll-5">
              <h1 className="h4 text-white mb-3">Caduceus Chain Explorer</h1>
              <SearchBox />
              <p className="text-white-70 mb-0" style={{ minHeight: '22px' }}></p>
            </div>
          </div>
          <div className="col-12 col-lg-5">
            <div className="d-none d-lg-block text-center pl-lg-4 pl-xll-5"></div>
          </div>
        </div>
      </div>
    </section>
  );
}

function Dashboard({ data = {} }) {
  const containerRef = useRef();
  const [percents, setPercents] = useState();
  const tradeHistoryChartRequest = useRequest(
    {
      url: '/blockBrowser/index/tradeHistoryChart',
      method: 'post',
    },
    { manual: true },
  );
  const boardRequest = useRequest(
    {
      url: '/blockBrowser/index/dashboard',
      method: 'post',
    },
    { manual: true },
  );

  const percent = ((data.current_price - data.open_price) / data.open_price) * 100;

  useEffect(() => {
    tradeHistoryChartRequest.run().then(res => {
      renderHighChart(containerRef.current, res);
    });
    boardRequest.run().then(res=>{
      setPercents(res.up_percent)
    })
  }, [containerRef]);
  return (
    <div className="card mb-4">
      <div className="card-body p-4">
        <div className="row mx-gutters-md-1 align-items-center">
          <div className="col-md-6 col-lg-4">
            <div className="media align-items-center">
              <figure className="mr-2">
                {/* <img className="u-sm-avatar" src="/images/svg/brands/huobi-icon.svg" alt="Caduceus" /> */}
              </figure>
              <div className="media-body">
                <h2 className="font-size-1 text-uppercase text-secondary mb-0">CMP Price</h2>
                <span className="text-size-1" rel="tooltip" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="View Historical CMP Price">
                  ${data.current_price}
                  {/* <span className="text-secondary"> @ {data.ht_btc} BTC</span> */}
                  <span className="text-danger small"> ({percents})</span>
                </span>
              </div>
            </div>
            <hr className="hr-space-lg" />
            <div className="media align-items-center">
              <figure className="u-sm-avatar mr-2">
                <img src="/images/svg/icons/icon-8.svg" alt="Market Cap" />
              </figure>
              <div className="media-body">
                <h2 className="font-size-1 text-uppercase text-secondary mb-0">FULLY DILUTED MARKET CAP</h2>
                <span className="text-size-1" rel="tooltip" data-placement="bottom" title="" data-original-title="Market cap of CMP on Caduceus Chain, click to Learn More">
                  ${Number(data.market_value).toLocaleString()} <span className="text-secondary">({Number(data.price_content).toLocaleString()} CMP)</span>
                </span>
              </div>
            </div>
            <hr className="d-block d-md-none hr-space-lg" />
          </div>
          <div className="col-md-6 col-lg-4 u-ver-divider u-ver-divider--left u-ver-divider--none-md">
            <div className="media align-items-center">
              <figure className="u-sm-avatar mr-2">
                <img src="/images/svg/icons/icon-2-1.svg?v=1.3" alt="Network Difficulty" />
              </figure>
              <div className="media-body">
                <h2 className="font-size-1 text-uppercase text-secondary mb-0">Latest Block</h2>
                <a className="text-size-1 text-link" href="blocks" rel="tooltip" data-placement="bottom" title="" data-original-title="The latest Block No">
                  <span>{data.latest_block}</span>
                </a>
                &nbsp;
                <a
                  data-toggle="tooltip"
                  href="/chart/blocktime"
                  className="small text-secondary  text-link"
                  data-placement="right"
                  data-title="Average Block Time (The latest 5000 blocks)"
                  data-original-title=""
                  title=""
                >
                  <span>3.0s</span>
                </a>
              </div>
              <div className="text-right">
                <h2 className="font-size-1 text-uppercase text-secondary mb-0">Transactions</h2>
                <a
                  href="/txs"
                  className="text-size-1 text-link"
                  rel="tooltip"
                  data-toggle="tooltip"
                  data-placement="left"
                  data-html="true"
                  title=""
                  data-original-title="Total Transactions Counter<br>(Update every 5 mins)"
                >
                  {data.trade_total > 100000 ? (data.trade_total / 1000) + ' K' : data.trade_total > 10000000 ? (data.trade_total / 1000000) + ' M' : data.trade_total}
                </a>
                <span className="text-secondary small" rel="tooltip" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Transactions per Second">
                  &nbsp; ({data.trade_total_tps} TPS)
                </span>
              </div>
            </div>
            <hr className="hr-space-lg" />
            <div className="media align-items-center">
              <figure className="u-sm-avatar mr-2">
                {/* <img src="/images/svg/icons/icon-51.svg?v=1.5" alt="SVG" /> */}
              </figure>
              <div className="media-body">
                {/* <h2 className="font-size-1 text-uppercase text-secondary mb-0">Active Validator</h2> */}
                <a className="text-size-1 text-link" data-toggle="tooltip" data-placement="left" data-title="View Difficulty Growth Chart" href="/chart/difficulty" data-original-title="" title=""></a>
                <a className="text-size-1 text-link" href="validatorset/snapshot/4761857" rel="tooltip" data-placement="bottom" title="" data-original-title="Total Active Validator">
                  {/* {data.active_verifier} */}
                </a>
              </div>
              <div className="text-right">
                <h2 className="font-size-1 text-uppercase text-secondary mb-0">Total Staked</h2>
                {data.verifier_total} CMP
              </div>
            </div>
            <hr className="d-block d-md-none hr-space-lg" />
          </div>
          <div className="col-md-12 col-lg-4 u-ver-divider u-ver-divider--left u-ver-divider--none-lg">
            <hr className="d-none d-md-block d-lg-none my-4" />
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="font-size-1 text-uppercase text-secondary mb-0">Caduceus TRANSACTION HISTORY LAST 14 DAYS</h2>
              <div className="position-relative float-right z-index-2">
                <a
                  className="btn btn-xs btn-icon btn-soft-secondary"
                  href="#"
                  role="button"
                  aria-controls="customcharts"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-unfold-event="click"
                  data-unfold-target="#customcharts"
                  data-unfold-type="css-animation"
                  data-unfold-duration="300"
                  data-unfold-delay="300"
                  data-unfold-hide-on-scroll="true"
                  data-unfold-animation-in="slideInUp"
                  data-unfold-animation-out="fadeOut"
                >
                  <i className="fa fa-ellipsis-v btn-icon__inner"></i>
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right dropdown-unfold u-unfold--css-animation u-unfold--hidden fadeOut"
                  aria-labelledby="customchartsinvoker"
                  style={{ animationDirection: '300ms' }}
                >
                  <a className="dropdown-item" href="/chart/tx">
                    View Detailed Chart
                  </a>
                </div>
              </div>
            </div>
            <div>
              <div ref={containerRef} className="" style={{ height: '106px' }} data-highcharts-chart="0"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function LatestBlock({ data = [] }) {
  return (
    <div className="mCustomScrollBox mCS-minimal-dark mCSB_vertical mCSB_outside" style={{ maxHeight: 'none', tabindex: '0' }}>
      <div className="mCSB_container" style={{ position: 'relative', top: 0, left: 0 }} dir="ltr">
        {data.map((item, index) => {
          const now = new Date().getTime();
          const time = moment(now - Number(item.block_time_interval) * 1000)
            .startOf('minute')
            .fromNow();

          return (
            <div key={item.block_no}>
              {index !== 0 ? <hr className="hr-space" /> : undefined}
              <div key={item.block_no} className="row">
                <div className="col-sm-4">
                  <div className="media align-items-sm-center mr-4 mb-1 mb-sm-0">
                    <div className="d-none d-sm-flex mr-2">
                      <span className="btn btn-icon btn-soft-secondary">
                        <span className="btn-icon__inner text-dark">Bk</span>
                      </span>
                    </div>
                    <div className="media-body">
                      <span className="d-inline-block d-sm-none">Block</span> <a href={`/block/${item.block_no}`}>{item.block_no}</a>
                      <span className="d-sm-block small text-secondary ml-1 ml-sm-0 text-nowrap" data-countdown="5000">
                        {time}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="d-flex justify-content-between">
                    <div className="text-nowrap">
                      <span className="d-block mb-1 mb-sm-0">
                        Validated By&nbsp;
                        <a className="hash-tag text-truncate" href={`/address/${item.block_miner}`}>
                          {item.block_miner}
                        </a>
                      </span>
                      <a href={`/txs?block=${item.block_no}`} title="Transactions in this Block">
                        {item.block_trade_amount} txns
                      </a>
                      &nbsp;
                      <span className="small text-secondary">in {item.block_interval_time} secs</span>
                      <span className="d-inline-block d-sm-none">
                        <span className="u-label u-label--xs u-label--badge-in u-label--secondary text-center text-nowrap" title="Block Reward">
                          {item.block_reward} CMP
                        </span>
                      </span>
                    </div>
                    <div className="d-none d-sm-block">
                      <span className="u-label u-label--xs u-label--badge-in u-label--secondary text-center text-nowrap" title="Block Reward">
                        {item.block_reward} CMP
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function LatestTrade({ data = [] }) {
  return (
    <div className="mCustomScrollBox mCS-minimal-dark mCSB_vertical mCSB_outside" tabIndex="0" style={{ maxHeight: 'none' }}>
      <div className="mCSB_container" style={{ position: 'relative', top: 0, left: 0 }} dir="ltr">
        {data.map((item, index) => {
          const now = new Date().getTime();
          const time = moment(now - Number(item.trade_time_interval) * 1000)
            .startOf('minute')
            .fromNow();
          return (
            <div key={item.trade_hash}>
              <div className="row">
                <div className="col-sm-4">
                  <div className="media align-items-sm-center mr-4 mb-1 mb-sm-0">
                    <div className="d-none d-sm-flex mr-2">
                      <span className="btn btn-icon btn-soft-secondary rounded-circle">
                        <span className="btn-icon__inner text-dark">Tx</span>
                      </span>
                      &nbsp;
                    </div>
                    <div className="media-body">
                      <span className="d-inline-block d-sm-none mr-1">Tx#</span>
                      <a className="hash-tag hash-tag--xs hash-tag-xs-down--md text-truncate" href={`/tx/${item.trade_hash}`}>
                        {item.trade_hash}
                      </a>
                      <span className="d-none d-sm-block small text-secondary">
                        <div data-countdown="5000">{time}</div>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="d-sm-flex justify-content-between">
                    <div className="text-nowrap mr-4 mb-1 mb-sm-0">
                      <span>
                        From&nbsp;
                        <a className="hash-tag text-truncate" href={`/address/${item.trade_from}`}>
                          {item.trade_from}
                        </a>
                      </span>
                      <span className="d-sm-block">
                        To&nbsp;
                        <a href={`/address/${item.trade_to}`} className="hash-tag hash-tag--sm text-truncate">
                          {item.trade_to}
                        </a>
                      </span>
                    </div>
                    <div>
                      &nbsp;
                      <span className="u-label u-label--xs u-label--badge-in u-label--secondary text-center text-nowrap" title="Amount">
                        {item.trade_amount} CMP
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="hr-space" />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default function Welcome() {
  const [dashboard, setDashboard] = useState();
  const [latestBlock, setLatestBlock] = useState();
  const [latestTrade, setLatestTrade] = useState();
  
  const messageHistory = useRef([]);

  const { lastMessage, readyState } = useWebSocket('wss://mainnet.scan.caduceus.foundation/blockBrowser/index/ws');

  messageHistory.current = useMemo(() => lastMessage);
  const dashboardRequest = useRequest(
    {
      url: '/blockBrowser/index/dashboard',
      method: 'post',
    },
    { manual: true },
  );
  const latestBlockRequest = useRequest(
    {
      url: '/blockBrowser/index/latestBlock',
      method: 'post',
    },
    { manual: true },
  );
  const latestTradeRequest = useRequest(
    {
      url: '/blockBrowser/index/latestTrade',
      method: 'post',
    },
    { manual: true },
  );
  

  useEffect(() => {
    async function fetch() {
      dashboardRequest.run().then(res => {
        setDashboard(res);
      });
      latestBlockRequest.run().then(res => {
        setLatestBlock(res);
      });
      latestTradeRequest.run().then(res => {
        setLatestTrade(res);
      });
    }

    fetch();
  }, []);

  useEffect(() => {
    try {
      const data = JSON.parse(lastMessage.data);
      if (Array.isArray(data.block) && data.block.length) {
        const latest = data.block[0];

        if (Array.isArray(latestBlock) && latestBlock.every(block => block.block_no !== latest.block_no)) {
          let blocks = [...latestBlock];
          blocks.unshift(latest);
          blocks.pop();
          setLatestBlock(blocks);
        }
        // block = data.block;
      }
      if (data.trade) {
        setLatestTrade(data.trade);
      }
    } catch (error) { }
  }, [lastMessage]);

  let block = latestBlock;
  let trade = latestTrade;

  return (
    <main>
      <TopHead />

      <div className="container space-bottom-1 mt-n5">
        <Dashboard data={dashboard} />
        <div className="row mb-5">
          <div className="col-lg-6 mb-4 mb-lg-0">
            <div className="card h-100">
              <div className="card-header">
                <h2 className="card-header-title">Latest Blocks</h2>
              </div>
              <div className="js-scrollbar card-body  mCustomScrollbar _mCS_1 mCS-autoHide" style={{ height: '400px', position: 'relative', overflowY: 'scroll' }}>
                {
                  latestBlockRequest.loading ? <Loading /> : <LatestBlock data={block || []} />
                }
                <div className="mCSB_scrollTools mCSB_1_scrollbar mCS-minimal-dark mCSB_scrollTools_vertical" style={{ display: 'block' }}>
                  <div className="mCSB_draggerContainer">
                    <div className="mCSB_dragger" style={{ position: 'absolute', minHeight: '50px', display: 'block', height: '224px', maxHeight: '366px', top: '0px' }}>
                      <div className="mCSB_dragger_bar" style={{ lineHeight: '50px' }}></div>
                    </div>
                    <div className="mCSB_draggerRail"></div>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <a className="btn btn-xs btn-block btn-soft-primary" href="/blocks">
                  View all blocks
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="card h-100">
              <div className="card-header">
                <h2 className="card-header-title">Latest Transactions</h2>
              </div>
              <div className="js-scrollbar card-body  mCustomScrollbar _mCS_2 mCS-autoHide" style={{ height: '400px', position: 'relative', overflow: 'scroll' }}>
                
                {latestTradeRequest.loading ? <Loading /> : <LatestTrade data={trade} />}
                <div className="mCSB_scrollTools mCSB_2_scrollbar mCS-minimal-dark mCSB_scrollTools_vertical" style={{ display: 'block' }}>
                  <div className="mCSB_draggerContainer">
                    <div className="mCSB_dragger" style={{ position: 'absolute', minHeight: '50px', display: 'block', height: '224px', maxHeight: '366px', top: 0 }}>
                      <div className="mCSB_dragger_bar" style={{ lineHeight: '50px' }}></div>
                    </div>
                    <div className="mCSB_draggerRail"></div>
                  </div>
                </div>
              </div>
              <div className="card-footer">
                <a className="btn btn-xs btn-block btn-soft-primary" href="/txs">
                  View all transactions
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
