import moment from 'moment';
import { useEffect, useState, useContext } from 'react';
import { useRequest } from 'ahooks';
import { Button, Form, Input, message, Tooltip, Checkbox } from 'antd';
import { useLocation, useParams } from 'react-router-dom';

import Loading from '../../components/Loading';
import Copy from '../../components/Copy';
import UserContext from '../../UserContext';
import React from 'react';

function TestnetTip() {
  return (
    <div className='col-md-12 text-danger  font-weight-bold font-weight-sm-normal mb-1 mb-md-0'>
      [ This is a CMP <strong>Testnet</strong> transaction only ]
    </div>
  )
}

function TradeHash({ data }) {
  const [copied, setCopied] = useState(false);
  return (
    <div className="row align-items-center mt-1">
      <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
        <i
          className="fal fa-question-circle text-secondary d-none d-sm-inline-block mr-1"
          data-container="body"
          data-toggle="popover"
          data-placement="top"
          data-original-title=""
          title=""
          data-content="A TxHash or transaction hash is a unique 66 characters identifier that is generated whenever a transaction is executed."
        ></i>
        Transaction Hash:
      </div>
      <div className="col-md-9">
        <span className="mr-1">{data.trade_hash}</span>
        <Copy text={data.trade_hash} title="Copy Txn Hash to clipboard" />
      </div>
    </div>
  );
}

function Status({ data }) {
  return (
    <div className="row align-items-center mn-3">
      <div className="col-auto col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
        <i
          className="fal fa-question-circle text-secondary d-none d-sm-inline-block mr-1"
          data-container="body"
          data-toggle="popover"
          data-placement="top"
          data-original-title=""
          title=""
          data-content="The status of the transaction."
        ></i>
        Status:
      </div>
      {data.status === 1 ? (
        <div className="col col-md-9">
          <span
            className="u-label u-label--sm u-label--success rounded"
            data-toggle="tooltip"
            title=""
            data-original-title="A Status code indicating if the top-level call succeeded or failed (applicable for Post BYZANTIUM blocks only)"
          >
            <i className="fa fa-check-circle mr-1"></i>Success
          </span>
        </div>
      ) : data.status === 2 ? (
        <div className="col col-md-9">
          <span
            className="u-label u-label--sm u-label--warning rounded"
            data-toggle="tooltip"
            title=""
            data-original-title="A Status code indicating if the top-level call succeeded or failed (applicable for Post BYZANTIUM blocks only)"
          >
            <i className="fa fa-question-circle mr-1"></i>Pending
          </span>
        </div>
      ) : (
        <div className="col col-md-9">
          <span
            className="u-label u-label--sm u-label--danger rounded"
            data-toggle="tooltip"
            title=""
            data-original-title="A Status code indicating if the top-level call succeeded or failed (applicable for Post BYZANTIUM blocks only)"
          >
            <i className="fa fa-times-circle mr-1"></i>
            {data.err_info ? 'Fail: ' + data.err_info : 'Fail'}
          </span>
        </div>
      )}
    </div>
  );
}

function BlockNo({ data }) {
  return (
    <div className="row align-items-center">
      <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
        <i
          className="fal fa-question-circle text-secondary d-none d-sm-inline-block mr-1"
          data-container="body"
          data-toggle="popover"
          data-placement="top"
          data-original-title=""
          title=""
          data-content="The number of the block in which the transaction was recorded. Block confirmation indicate how many blocks since the transaction is validated."
        ></i>
        Block:
      </div>
      <div className="col-md-9">
        <a href={`/block/${data.block_no}`}>{data.block_no}</a>
        <span className="u-label u-label--xs u-label--badge-in u-label--secondary ml-1" data-toggle="tooltip" title="" data-original-title="Number of blocks validated since">
          {data.block_confirmations || 0} Block Confirmations
        </span>
      </div>
    </div>
  );
}

function TimeStamp({ data }) {
  const now = new Date().getTime();
  const timeInterval = moment(now - Number(data.time_stamp_interval) * 1000)
    .startOf('minute')
    .fromNow();
  return (
    <div className="row align-items-center">
      <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
        <i
          className="fal fa-question-circle text-secondary d-none d-sm-inline-block mr-1"
          data-container="body"
          data-toggle="popover"
          data-placement="top"
          data-original-title=""
          title=""
          data-content="The date and time at which a transaction is validated."
        ></i>
        Timestamp:
      </div>
      <div className="col-md-9">
        <span></span>
        <i className="far fa-clock small mr-1"></i>
        <span>{timeInterval}</span>
        <span> ({moment.utc(data.time_stamp * 1000).format('MMM-D-YYYY HH:mm:ss A +z')})</span>
      </div>
    </div>
  );
}

function TradeFrom({ data }) {
  return (
    <div className="row align-items-center">
      <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
        <i
          className="fal fa-question-circle text-secondary d-none d-sm-inline-block mr-1"
          data-container="body"
          data-toggle="popover"
          data-placement="top"
          data-original-title=""
          title=""
          data-content="The sending party of the transaction (could be from a contract address)."
        ></i>
        From:
      </div>
      <div className="col-md-9">
        <a className="mr-1" href={`/address/${data.trade_from}`}>
          {data.trade_from}
        </a>
        {data.private_from_tag && <Tooltip title={<span>{data.private_from_tag && <span>My Name Tag:{data.private_from_tag}<br /></span>}({data.trade_from})<br />{data.public_from_tag && <span>Public Tag:{data.public_from_tag}</span>}</span>}>
          <span className="u-label u-label--value u-label--secondary text-dark rounded mr-1">
            {data.private_from_tag}
          </span>
        </Tooltip>}
        <Copy text={data.trade_from} title="Copy From Address to clipboard" />
      </div>
    </div>
  );
}

function TradeTo({ data }) {
  if (Array.isArray(data.trade_to_list)) {
    return (
      <div className="row">
        <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
          <i
            className="fal fa-question-circle text-secondary d-none d-sm-inline-block mr-1"
            data-container="body"
            data-toggle="popover"
            data-placement="top"
            data-original-title=""
            title=""
            data-content="The receiving party of the transaction (could be a contract address)."
          ></i>
          To:
        </div>
        <div className="col-md-9">
          <a className="js-go-To js-tab-external-link" href="#internal" data-target="#nav_tabs" data-type="static">
            <i className="fa fa-search-plus small mb-2" data-toggle="tooltip" title="" data-original-title="View Contract Internal Transactions"></i>
          </a>
          <span>{data.trade_to_contract == true ? "Contract" : ""}</span>
          <a id="contractCopy" href={`/address/${data.trade_to}`} className="wordwrap mr-1">
            {data.trade_to}
          </a>
          {data.private_to_tag && <Tooltip title={<span>{data.private_to_tag && <span>My Name Tag:{data.private_to_tag}<br /></span>}({data.trade_from})<br />{data.public_to_tag && <span>Public Tag:{data.public_to_tag}</span>}</span>}>
            <span className="u-label u-label--value u-label--secondary text-dark rounded mr-1">
              {data.private_to_tag}
            </span>
          </Tooltip>}
          <i className="fa fa-check-circle text-success mx-1 mb-2" data-toggle="tooltip" data-placement="top" data-title="Contract Execution Completed" data-original-title="" title=""></i>
          <Copy text={data.trade_to} title="Copy To Address to clipboard" />
          <ul className="list-unstyled mb-0" id="wrapperContent">
            {data.trade_to_list.map((item, i) => {
              return (
                <li className="media align-items-baseline" key={i}>
                  <div className="small mb-1">
                    <img src="/images/svg/shapes/shape-1.svg?v=0.01" className="mt-n1 ml-1" width="8" />
                    &nbsp;<span className="text-secondary">TRANSFER</span> &nbsp;{item.trade_value} CMP <span className="text-secondary">From</span>
                    <a href={`/address/${item.trade_from}`} className="hash-tag text-truncate">
                      <span className="hash-tag text-truncate tooltip-address" data-toggle="tooltip" title="" data-original-title={item.trade_from}>
                        {item.trade_from_name ? item.trade_from_name : item.trade_from}
                      </span>
                    </a>
                    <span className="text-secondary">To</span>
                    <i className="fa fa-long-arrow-alt-right text-secondary"></i>
                    <a href={`/address/${item.trade_to}`} className="hash-tag text-truncate">
                      <span className="hash-tag text-truncate tooltip-address" data-toggle="tooltip" title="" data-original-title={item.trade_to}>
                        {item.trade_to_name ? item.trade_to_name : item.trade_to}
                      </span>
                    </a>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
  return (
    <div className="row align-items-center">
      <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
        <i
          className="fal fa-question-circle text-secondary d-none d-sm-inline-block mr-1"
          data-container="body"
          data-toggle="popover"
          data-placement="top"
          data-original-title=""
          title=""
          data-content="The sending party of the transaction (could be from a contract address)."
        ></i>
        To:
      </div>
      <div className="col-md-9">
        <a className="mr-1" href={`/address/${data.trade_to}`}>
          {data.trade_to}
        </a>
        <Copy text={data.trade_to} title="Copy To Address to clipboard" />
      </div>
    </div>
  );
}





function Value({ data }) {
  // if (Number(data.time_stamp_interval) * 1000 < 24 * 60 * 60 * 1000) {
  //   return <></>;
  // }
  return (
    <div className="row align-items-center mn-3">
      <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
        <i
          className="fal fa-question-circle text-secondary d-none d-sm-inline-block mr-1"
          data-container="body"
          data-toggle="popover"
          data-placement="top"
          data-original-title=""
          title=""
          data-content="The value being transacted in CMP and fiat value. Note: You can click the fiat value (if available) to see historical value at the time of transaction."
        ></i>
        Value:
      </div>
      <div className="col-md-9">
        <span>
          <span data-toggle="tooltip" title="" data-original-title="The amount of CMP to be transferred to the recipient with the transaction">
            <span className="u-label u-label--value u-label--secondary text-dark rounded mr-1">
              {data.value} CMP
            </span> (${Number(data.value) * Number(data.currency_price)})
          </span>
        </span>
      </div>
    </div>
  );
}










function TokensTransferred({ data }) {
  const leg = data.tokens_transferred.length;
  return (
    <div className="row">
      <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
        <div className="d-flex align-items-center">
          <span className="mr-1" title={`${leg} Token Transfers`}>
            <i
              className="fal fa-question-circle text-secondary d-none d-sm-inline-block mr-1"
              data-container="body"
              data-toggle="popover"
              data-placement="top"
              data-original-title=""
              title=""
              data-content="List of token transferred in the transaction."
            ></i>
            Tokens Transferred:
          </span>
          {leg > 1 ? <span className="badge badge-pill badge-secondary align-midle">{leg}</span> : undefined}
        </div>
      </div>
      <div className="col-md-9">
        <ul className="list-unstyled mb-0">
          {React.Children.toArray(
            data.tokens_transferred.map((item, i) => {
              return (
                <li className={`media align-items-baseline ${i === leg - 1 ? 'mb-0' : 'mb-2'}`}>
                  <span className="row-count text-secondary small mr-1">
                    <i className="fa fa-caret-right"></i>
                  </span>
                  <div className="media-body">
                    <span className="">
                      <b>From</b>{' '}
                    </span>
                    <span className="hash-tag text-truncate mr-1">
                      <a href={`/token/${item.contact_address}?a=${item.trade_from}`}>
                        <span className="hash-tag text-truncate hash-tag-custom-from tooltip-address"
                          data-toggle="tooltip"
                          title=""
                          data-original-title={item.trade_from}>
                          {item.trade_from}
                        </span>
                      </a>
                    </span>
                    <span className="mr-1">
                      <b>To</b>{' '}
                    </span>
                    <span className="hash-tag text-truncate">
                      <a href={`/token/${item.contact_address}?a=${item.trade_to}`}>
                        <span className="hash-tag text-truncate hash-tag-custom-to tooltip-address"
                          data-toggle="tooltip"
                          title=""
                          data-original-title={item.trade_to}>
                          {item.trade_to}
                        </span>
                      </a>
                    </span>
                    <span className="mr-1">
                      {' '}
                      <b>For</b>{' '}
                    </span>
                    <span className="mr-1">
                      <span data-toggle="tooltip" data-original-title="Current Price : $1.00 / HUSD">
                        {item.amount}
                      </span>
                    </span>
                    <img src={item.contact_image || '/images/main/empty-token.png'} className="mt-n1 mr-1" width="15" />
                    <a href={`/token/${item.contact_address}`}>
                      <span data-toggle="tooltip" title="" data-original-title={item.contact_name}>
                        <font color="">{item.contact_name}</font>
                      </span>{' '}
                      ({item.contact_symbol})
                    </a>
                  </div>
                </li>
              );
            }),
          )}
        </ul>
      </div>
    </div>
  );
}

function TokensTransferred721({ data }) {
  if (!Array.isArray(data.tokens_transferred_721)) {
    return null;
  }
  const leg = data.tokens_transferred_721.length;

  return (
    <div className="row">
      <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
        <i
          className="fal fa-question-circle text-secondary d-none d-sm-inline-block mr-1"
          data-container="body"
          data-toggle="popover"
          data-placement="top"
          data-original-title=""
          title=""
          data-content="List of token transferred in the transaction."
        ></i>
        <span>Tokens Transferred:</span>
        {leg > 1 ? <span className="badge badge-pill badge-secondary align-midle">{leg}</span> : undefined}
      </div>
      <div className="col-md-9">
        <ul className="list-unstyled mb-0" id="wrapperContent">
          {React.Children.toArray(
            data.tokens_transferred_721.map((item, i) => {
              return (
                <li className={`media align-items-baseline ${i === leg - 1 ? 'mb-0' : 'mb-2'}`}>
                  <span className="row-count text-secondary small mr-1">
                    <i className="fa fa-caret-right"></i>
                  </span>
                  <div className="media-body">
                    <span className="text-secondary mr-1"> From </span>
                    <span className="hash-tag text-truncate mr-1">
                      <a href={`/token/${item.contact_address}?a=${item.trade_from}`} className="hash-tag text-truncate">
                        <span className="hash-tag text-truncate hash-tag-custom-from-721 tooltip-address" data-toggle="tooltip" title="" data-original-title={item.trade_from}>
                          {item.trade_from}
                        </span>
                      </a>
                    </span>
                    <span className="text-secondary mr-1"> To </span>
                    <span className="hash-tag text-truncate">
                      <a href={`/token/${item.contact_address}?a=${item.trade_to}`}>
                        <span className="hash-tag text-truncate hash-tag-custom-to-721 tooltip-address" data-toggle="tooltip" title="" data-original-title={item.trade_to}>
                          {item.trade_to}
                        </span>
                      </a>
                    </span>
                    <span className="ml-1 mr-1">
                      <b>For</b> &nbsp;CRC-721 TokenID [
                      <a href={`/token/${item.contact_address}?a=${parseInt(item.token_id, 16)}`}>
                        {parseInt(item.token_id, 16)}
                      </a>
                      ] <img src="/images/main/empty-token.png" className="mt-n1 mr-1" width="15" />
                      <a href={`/token/${item.contact_address}`}>
                        <span data-toggle="tooltip" title="" data-original-title={item.contact_name}>
                          <font color="">{item.contact_name}</font>
                        </span>
                        ({item.contact_symbol})
                      </a>
                    </span>
                  </div>
                </li>
              );
            }),
          )}
        </ul>
      </div>
    </div>
  );
}

function TokensTransferred1155({ data }) {
  if (!Array.isArray(data.tokens_transferred_1155)) {
    return null;
  }
  const leg = data.tokens_transferred_1155.length;

  // console.log(data.tokens_transferred_1155,'5555555555555');

  return (
    <div className="row">
      <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
        <i
          className="fal fa-question-circle text-secondary d-none d-sm-inline-block mr-1"
          data-container="body"
          data-toggle="popover"
          data-placement="top"
          data-original-title=""
          title=""
          data-content="List of token transferred in the transaction."
        ></i>
        <span>Tokens Transferred:</span>
        {leg > 1 ? <span className="badge badge-pill badge-secondary align-midle">{leg}</span> : undefined}
      </div>
      <div className="col-md-9">
        <ul className="list-unstyled mb-0" id="wrapperContent">
          {React.Children.toArray(
            data.tokens_transferred_1155.map((item, i) => {
              return (
                <li className={`media align-items-baseline ${i === leg - 1 ? 'mb-0' : 'mb-2'}`}>
                  <span className="row-count text-secondary small mr-1">
                    <i className="fa fa-caret-right"></i>
                  </span>
                  <div className="media-body">
                    <span className="text-secondary mr-1"> From </span>
                    <span className="hash-tag text-truncate mr-1">
                      <a href={`/token/${item.contact_address}?a=${item.trade_from}`} className="hash-tag text-truncate">
                        <span className="hash-tag text-truncate hash-tag-custom-from-721 tooltip-address" data-toggle="tooltip" title="" data-original-title={item.trade_from}>
                          {item.trade_from}
                        </span>
                      </a>
                    </span>
                    <span className="text-secondary mr-1"> To </span>
                    <span className="hash-tag text-truncate">
                      <a href={`/token/${item.contact_address}?a=${item.trade_to}`}>
                        <span className="hash-tag text-truncate hash-tag-custom-to-721 tooltip-address" data-toggle="tooltip" title="" data-original-title={item.trade_to}>
                          {item.trade_to}
                        </span>
                      </a>
                    </span>
                    <span className="ml-1 mr-1">
                      &nbsp;CRC-1155 <span className="text-secondary mr-1">For</span><span>{item.amount} of</span>&nbsp;TokenID [
                      <a href={`/token/${item.contact_address}?a=${item.token_id}`}>
                        {item.token_id}
                      </a>
                      ] <img src="/images/main/empty-token.png" className="mt-n1 mr-1" width="15" />
                      <a href={`/token/${item.contact_address}`}>
                        <span data-toggle="tooltip" title="" data-original-title={item.contact_name}>
                          <font color="">{item.contact_name}</font>
                        </span>
                        ({item.contact_symbol})
                      </a>
                    </span>
                  </div>
                </li>
              );
            }),
          )}
        </ul>
      </div>
    </div>
  );
}

// function Value({data}) {
// if (Number(data.time_stamp_interval) * 1000 < 24 * 60 * 60 * 1000) {
//   return <></>;
// }

//   return (
//     <div className="row align-items-center mn-3">
//       <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
//         <i
//           className="fal fa-question-circle text-secondary d-none d-sm-inline-block mr-1"
//           data-container="body"
//           data-toggle="popover"
//           data-placement="top"
//           data-original-title=""
//           title=""
//           data-content="The value being transacted in CMP and fiat value. Note: You can click the fiat value (if available) to see historical value at the time of transaction."
//         ></i>
//         Value:
//       </div>
//       <div className="col-md-9">
//         <span>
//           <span data-toggle="tooltip" title="" data-original-title="The amount of CMP to be transferred to the recipient with the transaction">
//             <span className="u-label u-label--value u-label--secondary text-dark rounded mr-1">{data.value} CMP</span> (${Number(data.value) * Number(data.currency_price)})
//           </span>
//         </span>
//       </div>
//     </div>
//   );
// }

function TradeFee({ data }) {
  return (
    <div className="row align-items-center">
      <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
        <i
          className="fal fa-question-circle text-secondary d-none d-sm-inline-block mr-1"
          data-container="body"
          data-toggle="popover"
          data-placement="top"
          data-original-title=""
          title=""
          data-content="Amount paid to the miner for processing the transaction."
        ></i>
        Transaction Fee:
      </div>
      <div className="col-md-9">
        <span>
          <span data-toggle="tooltip" title="" data-original-title="Gas Price * Gas Used by Transaction">
            {data.trade_fee} CMP (${Number(data.trade_fee) * Number(data.currency_price)})
          </span>
        </span>
      </div>
    </div>
  );
}

function CurrencyPrice({ data }) {
  return (
    <div className="row align-items-center">
      <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
        <i
          className="fal fa-question-circle text-secondary d-none d-sm-inline-block mr-1"
          data-container="body"
          data-toggle="popover"
          data-placement="top"
          data-original-title=""
          title=""
          data-content="Closing price of CMP on date of transaction (May-05-2021)"
        ></i>
        CMP Price:
      </div>
      <div className="col-md-9">
        <span>${data.currency_price || 0} / CMP</span>
      </div>
    </div>
  );
}

function GasLimit({ data }) {
  return (
    <div className="row align-items-center">
      <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
        <i
          className="fal fa-question-circle text-secondary d-none d-sm-inline-block mr-1"
          data-container="body"
          data-toggle="popover"
          data-placement="top"
          data-original-title=""
          title=""
          data-content="Maximum amount of gas provided for the transaction. For normal CMP transfers, the value is 21,000. For contract this value is higher and bound by block gas limit."
        ></i>
        Gas Limit:
      </div>
      <div className="col-md-9">
        <span title="" data-toggle="tooltip" data-original-title="The amount of GAS supplied for this transaction to happen">
          {data.gas_limit}
        </span>
      </div>
    </div>
  );
}

function GasUsedByTrade({ data }) {
  const percent = (Number(data.gas_used_by_trade) / Number(data.gas_limit)) * 100;

  return (
    <div className="row align-items-center">
      <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
        <i
          className="fal fa-question-circle text-secondary d-none d-sm-inline-block mr-1"
          data-container="body"
          data-toggle="popover"
          data-placement="top"
          data-original-title=""
          title=""
          data-content="The exact units of gas that was used for the transaction."
        ></i>
        Gas Used by Transaction:
      </div>
      <div className="col-md-9">
        <span title="" data-toggle="tooltip" data-original-title="The amount of GAS used by this specific transaction alone">
          {data.gas_used_by_trade} ({percent.toFixed(2)}%)
        </span>
      </div>
    </div>
  );
}

function GasPrice({ data }) {
  return (
    <div className="row align-items-center">
      <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
        <i
          className="fal fa-question-circle text-secondary d-none d-sm-inline-block mr-1"
          data-container="body"
          data-toggle="popover"
          data-placement="top"
          data-original-title=""
          title=""
          data-content="Cost per unit of gas specified for the transaction, in CMP and Gwei. The higher the gas price the higher chance of getting included in a block."
        ></i>
        Gas Price:
      </div>
      <div className="col-md-9">
        <span title="" data-toggle="tooltip" data-original-title="The price offered to the miner to purchase this amount of GAS （per GAS）">
          {data.gas_price} CMP (1 Gwei)
        </span>
      </div>
    </div>
  );
}

function Nonce({ data }) {
  return (
    <div className="row  mn-3">
      <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
        <i
          className="fal fa-question-circle text-secondary d-none d-sm-inline-block mr-1"
          data-container="body"
          data-toggle="popover"
          data-placement="top"
          data-original-title=""
          title=""
          data-content="Sequential running number for an address, beginning with 0 for the first transaction. For example, if the nonce of a transaction is 10, it would be the 11th transaction sent from the sender's address."
        ></i>
        Nonce
        <span className="u-label u-label--xs u-label--badge-out u-label--secondary ml-1" title="" data-toggle="tooltip" data-original-title="Index position of Transaction in the block">
          Position
        </span>
      </div>
      <div className="col-md-9">
        <span data-toggle="tooltip" title="" data-original-title="Transaction Nonce">
          {data.nonce}
        </span>
        <span data-toggle="tooltip" title="" className="u-label u-label--xs u-label--badge-out u-label--secondary ml-1 mr-3" data-original-title="Index position of Transaction in the block">
          {data.trade_index}
        </span>
      </div>
    </div>
  );
}

function InputData({ data }) {
  return (
    <div className="row">
      <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
        <i
          className="fal fa-question-circle text-secondary d-none d-sm-inline-block mr-1"
          data-container="body"
          data-toggle="popover"
          data-placement="top"
          data-original-title=""
          title=""
          data-content="Additional information that is required for the transaction."
        ></i>
        Input Data:
      </div>
      <div className="col-md-9">
        <div data-target-group="inputDataGroup">
          <span
            data-toggle="tooltip"
            title=""
            data-original-title="The binary data that formed the input to the transaction, either the input data if it was a message call or the contract initialisation if it was a contract creation"
          >
            <textarea defaultValue={data.input_data} readOnly="" spellCheck="false" className="form-control bg-light text-secondary text-monospace p-3" rows="10"></textarea>
          </span>
          <div className="d-flex align-items-center mt-2">
            <div className="position-relative mr-2">
              <span className="dropdown">
                <button className="btn btn-xss btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  View Input As
                </button>
                <div className="dropdown-menu" aria-labelledby="btnconvert222_1">
                  <a href="#" className="dropdown-item active">
                    Default View
                  </a>
                  <a href="#" className="dropdown-item">
                    Original
                  </a>
                </div>
              </span>
            </div>
            <a href="#" className="js-animation-link btn btn-xss btn-secondary" data-target="#decodetab" data-link-group="inputDataGroup" data-animation-in="fade">
              <i className="fa fa-cubes mr-1"></i> Decode Input Data
            </a>
          </div>
        </div>
        <div data-target-group="inputDataGroup" style={{ display: 'none', opacity: 0 }}>
          <span>
            <div align="center">
              <img src="/images/main/loadingblock.svg" alt="Loading" />
            </div>
          </span>
          <div style={{ display: 'none' }}>
            <div className="js-scrollbar scrollbox mCustomScrollbar _mCS_1 mCS-autoHide mCS_no_scrollbar" style={{ position: 'relative', overflow: 'visible' }}>
              <div className="mCustomScrollBox mCS-minimal-dark mCSB_vertical mCSB_outside" style={{ maxHeight: 'none' }} tabIndex="0">
                <div className="mCSB_container mCS_y_hidden mCS_no_scrollbar_y" style={{ position: 'relative', top: 0, left: 0 }} dir="ltr">
                  <div className="bg-light table-responsive"></div>
                </div>
              </div>
              <div className="mCSB_scrollTools mCSB_1_scrollbar mCS-minimal-dark mCSB_scrollTools_vertical" style={{ display: 'none' }}>
                <div className="mCSB_draggerContainer">
                  <div className="mCSB_dragger" style={{ position: 'absolute', minHeight: '50px', top: 0 }}>
                    <div className="mCSB_dragger_bar" style={{ lineHeight: '50px' }}></div>
                  </div>
                  <div className="mCSB_draggerRail"></div>
                </div>
              </div>
            </div>
            <a href="#" className="js-animation-link btn btn-xss btn-secondary mt-2">
              <i className="fa fa-undo txhash-icon"></i> Switch Back
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

function PrivateNote({ data }) {
  const userContext = useContext(UserContext);
  const user = userContext.user || {};
  console.log(user, 'useruseruseruser');
  const [tipStatus, setTipStatus] = useState(1);

  let createObj = {
    tx_hash: data.trade_hash,
    note: ''
  }
  // 创建
  const noteCreateRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/txnNote/noteCreate',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );
  let editObj = {
    note_id: data.private_txn_note_id,
    note: ''
  }
  // 编辑
  const noteEditRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/txnNote/noteEdit',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );
  // 删除
  const noteDeleteRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/txnNote/noteDelete',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );
  const changeVal = (note, id) => (e) => {
    // 创建
    if (!note && e.target.value) {
      noteCreateRequest.run({ ...createObj, note: e.target.value }).then(res => {
        if (res.code == 0) {
          setTipStatus(2)
        }
      });
      // 编辑
    } else if (note && e.target.value) {
      noteEditRequest.run({ ...editObj, note: e.target.value }).then(res => {
        if (res.code == 0) {
          setTipStatus(2)
        }
      });
      // 删除
    } else if (note && !e.target.value) {
      noteDeleteRequest.run({ note_id: id }).then(res => {
        if (res.code == 0) {
          setTipStatus(3)
        }
      });
    }
  }

  if (!user.user_name) {
    return (
      <div className="row">
        <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
          <i
            className="fal fa-question-circle text-secondary d-none d-sm-inline-block mr-1"
            data-container="body"
            data-toggle="popover"
            data-placement="top"
            data-original-title=""
            title=""
            data-content="Private note to keep track of the transaction. Only viewable to CaduceusInfo's user who assign them."
          ></i>
          Private Note:{' '}
        </div>
        <div className="col-md-9">
          To access the Private Note feature, you must be <a href={`/login?returnTx=${data.trade_hash}`}>Logged In</a>
        </div>
      </div>
    );
  } else {
    // !data.private_txn_note
    return (
      <div className="row">
        <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
          <i
            className="fal fa-question-circle text-secondary d-none d-sm-inline-block mr-1"
            data-container="body"
            data-toggle="popover"
            data-placement="top"
            data-original-title=""
            title=""
            data-content="Private note to keep track of the transaction. Only viewable to CaduceusInfo's user who assign them."
          ></i>
          Private Note:
          <i
            className="fa fa-info-circle text-secondary"
            rel="tooltip"
            data-placement="right"
            style={{ display: 'none' }}
            title=""
            data-toggle="tooltip"
            data-original-title="You can attach a private note to this transaction which you can view when logged in"
          ></i>
        </div>
        <div className="col-md-9">
          <span>
            <textarea rows={2} cols="50" defaultValue={data.private_txn_note} style={{ color: 'dimgray' }} className="inputbox form-control form-control-xs mb-2" onBlur={changeVal(data.private_txn_note, data.private_txn_note_id)} />
            <span className="small text-secondary">
              {tipStatus == 2 && <i className='fa fa-check text-success mr-2' />}
              {tipStatus == 3 && <i className='fa fa-exclamation-circle text-secondary mr-2' />}
              {tipStatus == 1 && (`Tip: A private note (up to 100 characters) can be saved and is useful for transaction tracking. Please DO NOT store any passwords or private keys here.`)}
              {tipStatus == 2 && (`Your private Note has been successfully updated.`)}
              {tipStatus == 3 && (`The private note was successfully removed.`)}
            </span>
          </span>
        </div>
      </div>
    );
  }

}

function TradeDetail({ data }) {
  const [showMore, setShowMore] = useState(true);
  return (
    <div className="tab-pane fade show active" role="tabpanel" aria-labelledby="home-tab">
      <div className="card-body">
        {
          window.location.host !== 'mainnet.scan.caduceus.foundation' && window.location.host !== 'cmpscan.io' && <>
            <TestnetTip />
            <hr className="hr-space" />
          </>
        }
        <TradeHash data={data} />
        <hr className="hr-space" />
        <Status data={data} />
        <hr className="hr-space" />
        <BlockNo data={data} />
        <hr className="hr-space" />
        <TimeStamp data={data} />
        <hr className="hr-space" />
        <TradeFrom data={data} />
        <hr className="hr-space" />
        <TradeTo data={data} />
        <hr className="hr-space" />
        {Array.isArray(data.tokens_transferred) && data.tokens_transferred.length ? <TokensTransferred data={data} /> : undefined}
        {Array.isArray(data.tokens_transferred) && data.tokens_transferred.length ? <hr className="hr-space" /> : undefined}
        {Array.isArray(data.tokens_transferred_721) && data.tokens_transferred_721.length ? <TokensTransferred721 data={data} /> : undefined}
        {Array.isArray(data.tokens_transferred_721) && data.tokens_transferred_721.length ? <hr className="hr-space" /> : undefined}
        {Array.isArray(data.tokens_transferred_1155) && data.tokens_transferred_1155.length ? <TokensTransferred1155 data={data} /> : undefined}
        {Array.isArray(data.tokens_transferred_1155) && data.tokens_transferred_1155.length ? <hr className="hr-space" /> : undefined}
        <Value data={data} />
        <hr className="hr-space" />
        <TradeFee data={data} />
        <hr className="hr-space" />
        <CurrencyPrice data={data} />
        <hr className="hr-space" />
        <div className={`collapse ${showMore ? 'show' : ''}`}>
          <GasLimit data={data} />
          <hr className="hr-space" />
          <GasUsedByTrade data={data} />

          <hr className="hr-space" />
          <GasPrice data={data} />

          <hr className="hr-space" />
          <Nonce data={data} />

          <hr className="hr-space" />
          <InputData data={data} />
          <hr className="hr-space" />
        </div>
        <span>
          <a
            className={`d-block ${showMore ? '' : 'collapsed'}`}
            href="#"
            data-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="ContentPlaceHolder1_collapseContent"
            onClick={() => {
              setShowMore(!showMore);
            }}
          >
            <span>
              Click to see {showMore ? <span className="card-arrow-less">Less</span> : <span className="card-arrow-more">More</span>}
              <span className="card-btn-arrow card-btn-arrow--up ml-2">
                <span className="fas fa-arrow-up small"></span>
              </span>
            </span>
          </a>
        </span>
        <hr className="hr-space" />
        <PrivateNote data={data} />
      </div>
    </div>
  );
}

function InternalTradeDetail({ data }) {
  if (!Array.isArray(data.internal_trade_list)) {
    return null;
  }
  const leg = data.internal_trade_list.length;

  return (
    <div className="tab-pane fade active show" role="tabpanel" aria-labelledby="internal-tab">
      <div
        className="ml-auto float-right mr-4 mt-3 mb-4 d-none"
        data-container="body"
        data-toggle="popover"
        data-placement="top"
        data-original-title=""
        title=""
        data-content="Toggle between Simplified and Advanced view. The 'Advanced' view also shows zero value ETH transfers, while the 'Simple' view only shows ETH transfers with value. Name tags integration is not available in advanced view"
        style={{ display: 'none' }}
      >
        <div className="d-flex">
          <label className="checkbox-switch mb-0" data-toggle="tooltip" data-placement="top" data-original-title="" title="">
            <input type="checkbox" className="checkbox-switch__input" />
            <span className="checkbox-switch__slider"></span>
          </label>
          <span className="ml-1">Simple</span>
        </div>
      </div>
      <div className="card-body mt-1">
        <div className="mb-3">
          <i className="fa fa-puzzle-piece text-secondary"></i> The contract call <strong> From </strong>
          <span data-toggle="tooltip" title="" className="hash-tag text-truncate " data-original-title={data.trade_from}>
            <a href={`/address/${data.trade_from}`}>{data.trade_from}</a>
          </span>
          <strong> To </strong>
          <span data-toggle="tooltip" title="" className="hash-tag text-truncate " data-original-title={data.trade_to}>
            <a href={`/address/${data.trade_to}`}>{data.trade_to}</a>
          </span>
          produced {leg} Internal Transaction
        </div>
        <div className="table-responsive mb-2 mb-md-0">
          <table className="table table-hover table-align-middle">
            <thead className="thead-light">
              <tr>
                <th scope="col">Type Trace Address</th>
                <th scope="col" width="180">
                  From
                </th>
                <th scope="col" width="30"></th>
                <th scope="col">To</th>
                <th scope="col">Value</th>
                <th scope="col">Gas Limit</th>
              </tr>
            </thead>
            <tbody>
              {React.Children.toArray(
                data.internal_trade_list.map(item => {
                  return (
                    <tr>
                      <td>
                        <img className="mt-n1" width="8" src="/images/svg/shapes/shape-1.svg?v=0.01" />
                        &nbsp;<i className="fa fa-check-circle text-success small mr-1"></i>call_0_1
                      </td>
                      <td>
                        <span data-toggle="tooltip" title="" className="hash-tag text-truncate" data-original-title={item.from}>
                          <a href={`/address/${item.from}`}>{item.from}</a>
                        </span>
                      </td>
                      <td>
                        <span className="btn btn-xs btn-icon btn-soft-success rounded-circle">
                          <i className="fa fa-long-arrow-alt-right btn-icon__inner"></i>
                        </span>
                      </td>
                      <td>
                        <i className="fa fa-sign-in-alt text-secondary small mr-1" data-toggle="tooltip" title="" data-original-title="An External Address"></i>
                        <span data-toggle="tooltip" title="" className="hash-tag text-truncate" data-original-title={item.to}>
                          <a href={`/address/${item.to}`}>{item.to}</a>
                        </span>
                      </td>
                      <td>{item.value} CMP</td>
                      <td>{item.gas_used}</td>
                    </tr>
                  );
                }),
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function TradeDetailLogs({ data }) {
  const list = data.logs_list || [];
  return (
    <div className="tab-pane fade show active" role="tabpanel" aria-labelledby="eventlog-tab">
      <div className="card-body">
        <h6 className="font-weight-normal mb-4">Transaction Receipt Event Logs</h6>
        {list.map((item, index) => {
          return (
            <div key={item.order_no}>
              <div className="media">
                <div className="mt-1 mr-3">
                  <span className="btn btn-icon btn-soft-success rounded-circle">
                    <span className="btn-icon__inner">{item.order_no}</span>
                  </span>
                </div>
                <div className="media-body">
                  <dl className="row align-items-baseline mb-3">
                    <dt className="col-md-2 col-lg-1 text-md-right">
                      <h6 className="font-weight-bold mb-0">Address</h6>
                    </dt>
                    <dd className="col-md-10 mb-0">
                      <a className="text-break mr-2" href={`/address/${item.address}`}>
                        {item.address}
                      </a>
                      <span className="dropdown">
                        <button className="btn btn-xss btn-custom dropdown-toggle" type="button" id="matchDropdown-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i className="fa fa-search-plus"></i>
                        </button>
                        <div className="dropdown-menu" aria-labelledby="matchDropdown-0">
                          <a className="dropdown-item" href={`/txs?ea=${item.address}&amp;topic0=0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef`}>
                            Matches Topic[0]
                          </a>
                        </div>
                      </span>
                    </dd>
                  </dl>
                  <dl className="row align-items-baseline mb-3">
                    <dt className="col-md-2 col-lg-1 text-md-right">
                      <h6 className="mb-0">Topics</h6>
                    </dt>
                    <dd className="col-md-10 mb-0">
                      <ul className="list-unstyled list-xs-space mb-0">
                        {[item.topics1, item.topics2, item.topics3, item.topics4, item.topics5, item.topics6].map((topic, i) => {
                          if (!topic) {
                            return null;
                          }
                          return (
                            <li key={i}>
                              <span className="u-label u-label--xs u-label--secondary rounded mr-1">{i}</span>&nbsp;
                              {i > 0 ? <i className="fa fa-long-arrow-alt-right text-secondary mx-1"></i> : undefined}
                              <span className="text-monospace text-break">{item[`topics${i + 1}`]}</span>
                            </li>
                          );
                        })}
                      </ul>
                    </dd>
                  </dl>
                  <dl className="row align-items-baseline mb-0">
                    <dt className="col-md-2 col-lg-1 text-md-right">
                      <h6 className="font-italic mb-0">Data</h6>
                    </dt>
                    {item.data !== '0x' ? (
                      <dd className="col-md-10 mb-0">
                        <ul className="list-unstyled list-xs-space bg-light rounded p-4 mb-0">
                          <li>
                            <span className="dropdown">
                              <button className="btn btn-xss btn-custom dropdown-toggle" type="button" id="convert_button_1_1" data-toggle="dropdown" aria-haspopup="True" aria-expanded="False">
                                Hex
                              </button>
                              <div className="dropdown-menu" aria-labelledby="convert_button_1_1">
                                <a className="dropdown-item" href="#">
                                  Hex
                                </a>
                                <a className="dropdown-item" href="#">
                                  Number
                                </a>
                                <a className="dropdown-item" href="#">
                                  Text
                                </a>
                                <a className="dropdown-item" href="#">
                                  Address
                                </a>
                              </div>
                              <span>
                                <i className="fa fa-long-arrow-alt-right mx-1"></i>
                                <span className="text-monospace text-break" id="chunk_1_1">
                                  {item.data}
                                </span>
                              </span>
                            </span>
                          </li>
                        </ul>
                      </dd>
                    ) : undefined}
                  </dl>
                </div>
              </div>
              {index !== list.length - 1 ? <hr className="my-4" /> : undefined}
            </div>
          );
        })}
        <div className="mb-5"></div>
      </div>
    </div>
  );
}

function Empty({ tx }) {
  return (
    <div className="card-body">
      <div className="space-2 text-center">
        <img className="mb-5-alt darkmode" width="150" src="/assets/svg/empty-states/empty-search-state-dark.svg" alt="Search Not Found" />
        <img className="mb-5-alt normalMode" width="150" src="/assets/svg/empty-states/empty-search-state.svg" alt="Search Not Found" />
        <p className="lead mb-0">
          Oops! An invalid Txn hash has been entered:&nbsp;
          <br />
          <i>{tx}</i>
        </p>
      </div>
    </div>
  );
}

export default function Tx() {
  const userContext = useContext(UserContext);
  const user = userContext.user || {};
  const { tx } = useParams();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(location.hash.slice(1) || 'home'); // home, internal, eventlog
  const [toggleTools, setToggleTools] = useState(false);
  const [state, setState] = useState({
    tradeDetailBody: { field: 'trade_hash', value: tx },
    internalTradeDetailBody: { field: 'trade_hash', value: tx, start: '1', length: '50' },
    tradeDetailLogsBody: { field: 'trade_hash', value: tx, start: '1', length: '50' },
  });
  const [tradeDetail, setTradeDetail] = useState({});
  const [internalTradeDetail, setInternalTradeDetail] = useState({});
  const [tradeDetailLogs, setTradeDetailLogs] = useState({});

  const tradeDetailRequest = useRequest(
    body => ({
      url: '/blockBrowser/blockChain/trade/tradeDetail',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify({ field: 'trade_hash', value: tx }),
    }),
    { manual: true },
  );
  const internalTradeDetailRequest = useRequest(
    body => ({
      url: '/blockBrowser/blockChain/trade/internalTradeDetail',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true },
  );
  const tradeDetailLogsRequest = useRequest(
    body => ({
      url: '/blockBrowser/blockChain/trade/tradeDetailLogs',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true },
  );

  useEffect(() => {
    tradeDetailRequest.run(state.tradeDetailBody).then(res => {
      if (res) {
        setTradeDetail(res);
      }
      internalTradeDetailRequest.run(state.internalTradeDetailBody).then(res => {
        if (res) {
          setInternalTradeDetail(res);
        }
      });
      tradeDetailLogsRequest.run(state.tradeDetailLogsBody).then(res => {
        if (res) {
          setTradeDetailLogs(res);
        }
      });
    });
  }, []);

  if (tradeDetailRequest.loading) {
    return <Loading />;
  }

  return (
    <main role="main">
      <div className="container py-3 mn-b3">
        <div className="d-lg-flex justify-content-between align-items-center">
          <div className="mb-3 mb-lg-0">
            <h1 className="h4 mb-0">Transaction Details </h1>
          </div>
          <div className="d-flex flex-wrap mt-1 mt-md-0 flex-wrap ml-md-auto"></div>
        </div>
      </div>
      <div className="container space-bottom-2">
        <div className="card">
          <div className="card-header sticky-card-header d-flex justify-content-between p-0">
            <ul className="nav nav-custom nav-borderless nav_tabs1" role="tablist">
              <li className="nav-item">
                <a
                  className={`nav-link ${currentTab === 'home' ? 'active' : ''}`}
                  data-toggle="tab"
                  href="#home"
                  aria-controls="home"
                  aria-selected="true"
                  onClick={() => {
                    setCurrentTab('home');
                  }}
                >
                  Overview
                </a>
              </li>
              {internalTradeDetail && !!internalTradeDetail.internal_trade_list ? (
                <li className="nav-item">
                  <a
                    className={`nav-link ${currentTab === 'internal' ? 'active' : ''}`}
                    data-toggle="tab"
                    href="#internal"
                    aria-controls="internal"
                    aria-selected="false"
                    onClick={() => {
                      setCurrentTab('internal');
                    }}
                  >
                    Internal Txns
                  </a>
                </li>
              ) : undefined}
              {tradeDetailLogs && tradeDetailLogs.counts && tradeDetailLogs.counts !== '0' ? (
                <li className="nav-item">
                  <a
                    className={`nav-link ${currentTab === 'eventlog' ? 'active' : ''}`}
                    data-toggle="tab"
                    href="#eventlog"
                    aria-controls="eventlog"
                    aria-selected="false"
                    onClick={() => {
                      setCurrentTab('eventlog');
                    }}
                  >
                    Logs ({tradeDetailLogs.counts})
                  </a>
                </li>
              ) : undefined}
            </ul>
            <div className="d-flex mr-3 mt-3">
              <div style={{ display: 'none' }}>
                <img src="/images/main/EllipsisSpinner3.svg?v=0.0.1" />
              </div>
              <div className="position-relative">
                <a
                  href="#"
                  data-unfold-animation-out="fadeOut"
                  aria-expanded="false"
                  data-unfold-type="css-animation"
                  data-unfold-target="#dropdownTools"
                  data-unfold-animation-in="slideInUp"
                  className="btn btn-xs btn-icon btn-soft-secondary"
                  data-unfold-event="click"
                  data-unfold-delay="300"
                  aria-haspopup="true"
                  data-unfold-hide-on-scroll="false"
                  role="button"
                  aria-controls="dropdownTools"
                  data-unfold-duration="300"
                  onClick={e => {
                    setToggleTools(!toggleTools);
                  }}
                >
                  <i className="fa fa-ellipsis-v btn-icon__inner"></i>
                </a>
                <div
                  className={`dropdown-menu dropdown-menu-right dropdown-unfold u-unfold--css-animation ${toggleTools ? 'slideInUp' : 'u-unfold--hidden'}`}
                  aria-labelledby="dropdownToolsInvoker"
                  style={{ animationDuration: '300ms' }}
                >
                  <a href={`/remix?txhash=${tradeDetail.trade_hash}`} className="dropdown-item">
                    Remix Debugger
                  </a>
                  <hr className="my-1" />
                  <a href={`/vmtrace?txhash=${tradeDetail.trade_hash}`} className="dropdown-item">
                    Geth Debug Trace
                  </a>
                  <a href={`/vmtrace?txhash=${tradeDetail.trade_hash}&amp;type=gethtrace2`} className="dropdown-item">
                    Geth Debug Trace_2
                  </a>
                  <hr className="my-1" />
                  <a href={`/visualizer?tx=${tradeDetail.trade_hash}&amp;type=txn`} className="dropdown-item">
                    Txns Visualizer
                  </a>
                  <a href={`/visualizer?tx=${tradeDetail.trade_hash}&amp;type=tkn`} className="dropdown-item">
                    CRC-20 Token Visualizer
                  </a>
                </div>
              </div>
            </div>
          </div>
          {tradeDetailRequest.data ? (
            <div className="tab-content">
              {currentTab === 'home' ? <TradeDetail data={tradeDetail} /> : undefined}
              {currentTab === 'internal' ? <InternalTradeDetail data={internalTradeDetail} /> : undefined}
              {currentTab === 'eventlog' ? <TradeDetailLogs data={tradeDetailLogs} /> : undefined}
            </div>
          ) : (
            <Empty tx={tx} />
          )}
        </div>
      </div>
    </main>
  );
}
