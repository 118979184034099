import { useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import moment from 'moment';

import Pager from '../../components/Pager';
import Loading from '../../components/Loading';
import TimeTD from '../../components/TimeTD';

export default function TxsInternal() {
  const [showAs, setShowAs] = useState('Age');
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [state, setState] = useState({
    body: {
      start: query.p || '1',
      length: JSON.parse(sessionStorage.getItem('2')),
      field: query.block ? 'block_no' : undefined,
      value: query.block ? query.block : undefined,
    },
  });

  const handleChangePageSize = e => {
    setState({ ...state, body: { ...state.body, length: e.target.value } });
  };

  const contractInternalTradeListRequest = useRequest(
    body => ({
      url: '/blockBrowser/blockChain/contractInternalTrade/contractInternalTradeList',
      method: 'post',
      body: JSON.stringify(body),
    }),
    { manual: true },
  );

  useEffect(() => {
    contractInternalTradeListRequest.run(state.body);
  }, [state, showAs]);

  if (contractInternalTradeListRequest.loading) {
    return <Loading />;
  }

  const changeType = () => {
    let str = showAs == 'Age' ? 'Date Time (UTC)' : 'Age'
    setShowAs(str)
  }

  const data = contractInternalTradeListRequest.data?.contract_internal_trade_list || [];
  const counts = contractInternalTradeListRequest.data?.counts || 0;
  const contract_internal_trade_count = contractInternalTradeListRequest.data?.contract_internal_trade_count || 0;
  const totalPage = Math.ceil(Number(contract_internal_trade_count) / state.body.length);
  const length = state.body.length;

  window.sessionStorage.setItem('2', JSON.stringify(state.body.length))

  return (
    <main id="content" role="main">
      <div className="container py-3">
        <div className="d-sm-flex align-items-center">
          <div className="mb-2 mb-sm-0">
            <h1 className="h4 mb-0">
              <span>Contract Internal Transactions</span>
              {query.block ? (
                <p className="mb-0 text-break small">
                  <span className="small text-secondary ml-1">For Block {query.block}</span>
                </p>
              ) : undefined}
            </h1>
          </div>
        </div>
      </div>

      <span id="ContentPlaceHolder1_lblAdResult"></span>
      <div className="container space-bottom-2">
        <div className="card">
          <form method="post" action="./txsInternal?p=1" id="ctl00">
            <div className="card-body">
              <div className="d-md-flex justify-content-between mb-4">
                <p className="mb-2 mb-md-0">
                  <i id="spinwheel" className="fa fa-spin fa-spinner fa-1x fa-pulse mr-1" style={{ display: 'none' }}></i> A total of {counts} internal transactions found
                  {query.block ? undefined : <span className="d-block small">(Showing the last {contract_internal_trade_count} records only)</span>}
                </p>

                <Pager current={state.body.start} total={totalPage} path="/txsInternal" />
              </div>
              <div className="table-responsive mb-2 mb-md-0">
                <table className="table table-hover">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Block</th>
                      <th scope="col">
                        <a href="#" id="lnkAgeDateTime" title="" data-toggle-commonjs="tooltip" data-original-title="Click to show Datetime Format" onClick={changeType}>
                          {showAs}
                        </a>
                      </th>
                      <th scope="col">Parent Txn Hash</th>
                      <th scope="col">Type</th>
                      <th scope="col">From</th>
                      <th scope="col" width="30"></th>
                      <th scope="col">To</th>
                      <th scope="col">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, i) => {
                      const now = new Date().getTime();
                      const time = moment(now - Number(item.trade_time) * 1000)
                        .startOf('minute')
                        .fromNow();

                      return (
                        <tr key={i}>
                          <td className="bg-soft-secondary">{i === 0 || item.block_no !== data[i - 1].block_no ? <a href={`/block/${item.block_no}`}>{item.block_no}</a> : undefined}</td>

                          <TimeTD time={item.trade_time} interval={item.trade_time_interval} showAs={showAs} />

                          <td className="bg-light">
                            {/* <td className="border-danger bg-light"> */}
                            {/* <i className="fa fa-exclamation-circle text-danger" data-toggle="tooltip" data-title="Error occured during Contract Execution" data-original-title="" title=""></i> */}
                            <span className="hash-tag text-truncate">
                              <a href={`/tx/${item.parent_trade_hash}`}>{item.parent_trade_hash}</a>
                            </span>
                            <i className="fas fa-chevron-circle-right text-secondary"></i>
                          </td>
                          <td>{item.types}</td>
                          <td>
                            <i className="far fa-file-alt text-secondary" data-toggle="tooltip" title="" style={{ marginRight: '4px' }} data-original-title="Contract"></i>
                            <a className="hash-tag text-truncate" href={`/address/${item.trade_from}`} data-toggle="tooltip" title="" data-original-title={item.trade_from}>
                              {item.trade_from}
                            </a>
                          </td>
                          <td>
                            <span className="btn btn-xs btn-icon btn-soft-success rounded-circle">
                              <i className="fas fa-long-arrow-alt-right btn-icon__inner"></i>
                            </span>
                          </td>
                          <td>
                            {/* <i className="far fa-newspaper text-secondary hash-tag text-truncate" data-toggle="tooltip" title="" data-original-title="Contract creation failed"></i>&nbsp; */}
                            <a className="hash-tag text-truncate" href={`/address/${item.trade_to}`} data-toggle="tooltip" title="" data-original-title={item.trade_to}>
                              {item.trade_to_name ? item.trade_to_name : item.trade_to}
                            </a>
                          </td>
                          <td>{item.value} CMP</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="d-md-flex justify-content-between my-3">
                <div className="d-flex align-items-center text-secondary mb-2 mb-md-0">
                  Show
                  <select className="custom-select custom-select-xs mx-2" onChange={handleChangePageSize} defaultValue={length}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  Records
                </div>
                <Pager current={state.body.start} total={totalPage} path="/txsInternal" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
}
