import { useState, useContext, useEffect } from 'react';
import { useRequest } from 'ahooks';
import { Typography, Button, Checkbox, Form, Radio, Input, Space } from 'antd'
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';

import MySideBar from '../../components/MySideBar';
import UserContext from '../../UserContext';
import Pager from '../../components/Pager';

const { Paragraph } = Typography;

function Augment({ user, show, onHide }) {
  const [fields, setFields] = useState({
    address: '',
    description: '',
    notification_type: '',
    track_crc20_transfer: 0
  });
  const [addResult, setAddResult] = useState({});
  const watchCreateRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/watch/watchCreate',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );
  const handleAddWatch = () => {
    watchCreateRequest.run(fields).then(res => {
      if (res.code == 0) {
        setAddResult(res)
        setFields({
          address: '',
          description: '',
          notification_type: '',
          track_crc20_transfer: 0
        })
        setTimeout(() => {
          onHide();
          setAddResult({})
        }, 1500);
      }
    });
  };
  const showError = typeof watchCreateRequest.data?.code !== 'undefined' && watchCreateRequest.data?.code !== 0;
  const radioChange = (e) => {
    if (e.target.checked) {
      setFields({ ...fields, notification_type: Number(e.target.value) });
    }
  }
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className="p-2">
        <Modal.Title>Add a New Address to your Watch List</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {addResult.code === 0 ? (
          <div className="alert alert-success">
            <strong>Status:</strong>Successfully Added New Address {fields.address} to the watch list.
          </div>
        ) : ''}
        <div className='alert alert-danger' style={{ display: showError ? 'block' : 'none' }}>
          <strong>Status: </strong>
          {watchCreateRequest.data?.enMsg}
        </div>
        <div className="modal-body ml-1">
          <div className="form-group row">
            <label className="col-sm-3" htmlFor="ETHAddress">Address :</label>
            <div className="js-form-message input-group input-group-sm col-sm-9">
              <input name="ctl00$ContentPlaceHolder1$txtAddress"
                type="text"
                maxLength="42"
                id="ContentPlaceHolder1_txtAddress"
                className="form-control"
                required=""
                data-msg="Please enter address"
                data-error-class="u-has-error"
                value={fields.address}
                onChange={e => {
                  setFields({ ...fields, address: e.target.value });
                }}
                data-success-class="u-has-success" />
              <span id="ContentPlaceHolder1_RegularExpressionValidator1" style={{ color: 'red', fontSize: 'Smaller', display: 'none' }} >*Invalid Address</span>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-3" htmlFor="Description">Description :</label>
            <div className="input-group input-group-sm col-sm-9">
              <input name="ctl00$ContentPlaceHolder1$txtDescription"
                type="text"
                maxLength="100"
                id="ContentPlaceHolder1_txtDescription"
                className="form-control"
                placeholder="Please description"
                value={fields.description}
                onChange={e => {
                  setFields({ ...fields, description: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="mb-2">
            <span className="d-block">You can monitor and receive an alert when an address on your watch list receives an incoming Transaction.</span>
            <span className="d-block mt-4">Please select your notification method below :</span>
          </div>
          <div className="form-group">
            <div className="custom-control custom-radio form-check-inline">
              <input value="0"
                name="ctl00$ContentPlaceHolder1$NotificationType"
                type="radio"
                id="ContentPlaceHolder1_RadioButton0"
                className="custom-control-input"
                data-toggle="tooltip"
                title=""
                // checked="checked" 
                onChange={(e) => radioChange(e)}
                data-original-title="Select this option to Disable notification" />
              <label className="custom-control-label" htmlFor="ContentPlaceHolder1_RadioButton0">No Notification</label>
            </div>
            <div className="custom-control custom-radio form-check-inline">
              <input value="3"
                name="ctl00$ContentPlaceHolder1$NotificationType"
                type="radio"
                id="ContentPlaceHolder1_RadioButton1"
                className="custom-control-input"
                data-toggle="tooltip"
                title=""
                onChange={(e) => radioChange(e)}
                data-original-title="Select this option to receive Incoming &amp; Outgoing Txns Email notification" />
              <label className="custom-control-label" htmlFor="ContentPlaceHolder1_RadioButton1">Notify on Incoming &amp; Outgoing Txns</label>
            </div>
            <div className="custom-control custom-radio form-check-inline">
              <input value="1"
                name="ctl00$ContentPlaceHolder1$NotificationType"
                type="radio"
                id="ContentPlaceHolder1_RadioButton2"
                className="custom-control-input"
                data-toggle="tooltip"
                title=""
                onChange={(e) => radioChange(e)}
                data-original-title="Select this option to receive Incoming (Receive) Txns Email notification" />
              <label className="custom-control-label" htmlFor="ContentPlaceHolder1_RadioButton2">Notify on Incoming (Receive) Txns Only</label>
            </div>
            <div className="custom-control custom-radio form-check-inline">
              <input value="2"
                name="ctl00$ContentPlaceHolder1$NotificationType"
                type="radio"
                id="ContentPlaceHolder1_RadioButton3"
                className="custom-control-input"
                data-toggle="tooltip"
                title=""
                onChange={(e) => radioChange(e)}
                data-original-title="Select this option to receive Outgoing (Sent) Txns Email notification" />
              <label className="custom-control-label" htmlFor="ContentPlaceHolder1_RadioButton3">Notify on Outgoing (Sent) Txns Only</label>
            </div>
          </div>
          <div className="mb-2">
            <span className="d-block">Other Options</span>
          </div>
          <div className="form-group">
            <div className="custom-control custom-checkbox form-check-inline">
              <input name="ctl00$ContentPlaceHolder1$chkTokenTransfer"
                type="checkbox"
                id="ContentPlaceHolder1_chkTokenTransfer"
                className="custom-control-input"
                data-toggle="tooltip"
                title=""
                onChange={e => {
                  let checked = e.target.checked
                  setFields({ ...fields, track_crc20_transfer: checked ? 1 : 0 });
                }}
                data-original-title="Applicable only when 'Disable Email Notifications' is not selected" />
              <label className="custom-control-label" htmlFor="ContentPlaceHolder1_chkTokenTransfer">
                Also Track CRC-20 Token Transfers (click to Enable)
              </label>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button data-dismiss="modal" className="btn btn-sm btn-secondary btn-xs" type="button" causesvalidation="False" onClick={onHide}>
          Cancel
        </button>
        <input type="button" value="Continue" className="btn btn-sm btn-primary btn-xs" onClick={handleAddWatch} />
      </Modal.Footer>
    </Modal>
  )
}

function EditWatch({ data = {}, user, show, onHide }) {
  const [form] = Form.useForm();
  const [fields, setFields] = useState({
    watch_id: data.watch_id || '',
    description: data.description || '',
    notification_type: data.notification_type || '',
    track_crc20_transfer: data.track_crc20_transfer || '',
    address: data.address || ''
  });
  const [editResult, setEditResult] = useState({});
  const watchwatchEditRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/watch/watchEdit',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );

  const onFinish = (values) => {
    values.track_crc20_transfer = values.track_crc20_transfer ? 1 : 0
    values.watch_id = fields.watch_id
    watchwatchEditRequest.run({ ...values }).then(res => {
      if (res.code == 0) {
        setEditResult(res)
        setTimeout(() => {
          onHide();
        }, 1500);
      }
    })
  };
  const showError = typeof watchwatchEditRequest.data?.code !== 'undefined' && watchwatchEditRequest.data?.code !== 0;
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className="p-2">
        <Modal.Title>Address Watch Options</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {editResult.code === 0 ? (
          <div className="alert alert-success">
            <strong>Status:</strong>Successfully updated Address.
          </div>
        ) : ''}
        <div className='alert alert-danger' style={{ display: showError ? 'block' : 'none' }}>
          <strong>Status: </strong>
          {watchwatchEditRequest.data?.enMsg}
        </div>
        <div className="modal-body ml-1">
          <Form onFinish={onFinish} form={form}
            initialValues={{
              'notification_type': Number(fields.notification_type),
              'track_crc20_transfer': Number(fields.track_crc20_transfer) == 1 ? true : false
            }}>
            <Form.Item
              label="Address :"
              name="address"
              initialValue={fields.address}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="Description :"
              name="description"
              initialValue={fields.description}
            >
              <Input />
            </Form.Item>
            <Form.Item name="notification_type" label="Notification Settings :">
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={Number("0")}>No Notification</Radio>
                  <Radio value={Number("3")}>Notify on Incoming &amp; Outgoing Txns</Radio>
                  <Radio value={Number("1")}>Notify on Incoming (Receive) Txns Only</Radio>
                  <Radio value={Number("2")}>Notify on Outgoing (Sent) Txns Only</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="track_crc20_transfer" valuePropName="checked" label="Other Options : ">
              <Checkbox>Also Track CRC-20 Token Transfers (click to Enable)</Checkbox>
            </Form.Item>
            <Form.Item className='d-flex justify-content-center'>
              <Button htmlType="button" onClick={onHide} className="mr-4">Cancel</Button>
              <Button type="primary" htmlType="submit">Save Change</Button>
            </Form.Item>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  )
}

function Remove({ data = {}, user, show, onHide }) {
  const [delResult, setDelResult] = useState({});
  const watchDeleteRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/watch/watchDelete',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );
  const handleDelWatch = () => {
    watchDeleteRequest.run({ watch_id: data.watch_id }).then(res => {
      if (res.code == 0) {
        setDelResult(res)
        setTimeout(() => {
          onHide();
        }, 1500);
      }
    })
  };
  const showError = typeof watchDeleteRequest.data?.code !== 'undefined' && watchDeleteRequest.data?.code !== 0;
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className="p-2">
        <Modal.Title>Confirmation Required</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {delResult.code === 0 ? (
          <div className="alert alert-success">
            <strong>Status:</strong>Successfully removed Address Entry '{data.address}'
          </div>
        ) : ''}
        <div className='alert alert-danger' style={{ display: showError ? 'block' : 'none' }}>
          <strong>Status: </strong>
          {watchDeleteRequest.data?.enMsg}
        </div>
        <div className="modal-body">
          <p>Are you sure you wish to unlink the address?<br />
            <span className="text-secondary font-weight-normal" id="removetxhash">{data.address}</span>
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button data-dismiss="modal" className="btn btn-sm btn-secondary btn-xs" type="button" causesvalidation="False" onClick={onHide}>
          Close
        </button>
        <input type="button" value="Continue" className="btn btn-sm btn-primary btn-xs" onClick={handleDelWatch} />
      </Modal.Footer>
    </Modal>
  )
}

function AddressList({ user, update }) {
  const [showView, setShowView] = useState(false);
  const [removeView, setRemoveView] = useState(false);
  const [curWatch, setCurWatch] = useState();

  const [state, setState] = useState({
    body: {
      start: '1',
      length: '50',
      // field: 'key_name',
      // value: '',
    },
  });

  const listWatchRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/watch/watchList',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true },
  );

  useEffect(() => {
    listWatchRequest.run(state.body);
  }, [update]);

  const data = listWatchRequest.data?.watch_list || [];
  const counts = listWatchRequest.data?.counts || 0;
  const limit_counts = listWatchRequest.data?.limit_counts || 0;
  const grandTotal = listWatchRequest.data?.grand_total || 0;
  const cmpPrice = listWatchRequest.data?.cmp_price || 0;
  const totalPage = Math.ceil(Number(counts) / state.body.length);

  const handleCreateHide = () => {
    listWatchRequest.run(state.body);
    setShowView(false);
  };

  const handleCreateHideRemove = () => {
    listWatchRequest.run(state.body);
    setRemoveView(false);
  };

  const numFormat = (params) => {
    let res = params.toString().replace(/\d+/, function (num) { // 先提取整数部分
      return num.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
        return $1 + ",";
      });
    })
    return res;
  }

  return (
    <div className="card-body">
      <div className="d-md-flex justify-content-between align-items-center mb-4">
        <p className="mb-2 mb-lg-0">&nbsp;{counts} used (Out of {limit_counts} max quota)</p>
        <nav className="mb-4 mb-md-0" aria-label="page navigation">
          <div>
            <Pager current={state.body.start} total={totalPage} path="/txs" />
          </div>
        </nav>
      </div>
      <div className="table-responsive mb-2 mb-md-0">
        <div className="mx-md-auto">
          <figure id="SVGdataReport1" className="mb-7 ie-data-report">
            <table className="table">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Action</th>
                  <th scope="col">Address</th>
                  <th scope="col">Balance</th>
                  <th scope="col">Notification</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(data) && data.length !== 0 ? (
                  data.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td className="align-top" width="40px">
                          <a
                            className="btn btn-outline-secondary btn-xss"
                            // href="myaddress_modify?id=308481&amp;a=0x6b02de5859c8d6fcf7b4dd042730f5238fe4d35f" 
                            // href="myaddress_modify"
                            data-toggle="tooltip"
                            title=""
                            data-original-title="Modify Alert Notification options"
                            onClick={() => {
                              setShowView(!showView);
                              setCurWatch(item)
                            }}> Edit</a>&nbsp;
                          <a
                            data-toggle="modal"
                            data-target="#myModaldelete"
                            className="btn btn-outline-secondary btn-xss"
                            onClick={() => {
                              setRemoveView(!removeView);
                              setCurWatch(item)
                            }}
                            // onClick="javascript:getaddress('0x3556dcf354e0b31984cd59ec7c039aa291a11a0613a3053a09282a12e9402711');"
                            rel="tooltip"
                            title=""
                            data-original-title="Remove Watch"
                          >
                            Remove
                          </a>
                        </td>
                        <td className="align-top" width="260px">
                          <div className="d-flex clipboard-hover">
                            <a id={`spanTarget_${item.address}`}
                              href={`/address/${item.address}`}
                              className="hash-tag text-truncate">{item.address}</a>
                            <Paragraph copyable={{ text: item.address }} style={{ marginBottom: '0' }} />
                          </div>
                          <span className="small text-secondary mt-2 d-block">{item.description}</span>
                          <span className="small text-secondary mt-2 d-block" data-toggle="tooltip" title="" ml-1="" data-original-title="UTC Time Zone">Added on {moment(item.create_time).format('YYYY-MM-DD')}</span>
                        </td>
                        <td className="text-nowrap align-top">
                          <span className="d-block mb-3">
                            <div className="js-scrollbar overflow-hidden mt-2 mCustomScrollbar _mCS_1 mCS-autoHide" style={{ maxHeight: '230px!important', position: 'relative', overflow: 'visible' }} >
                              {item.balances.length && item.balances.map((val, idx) => {
                                return (
                                  <>
                                    <div key={idx} id="mCSB_1" className="mCustomScrollBox mCS-minimal-dark mCSB_vertical mCSB_outside" style={{ maxHeight: 'none' }} tabIndex="0">
                                      <div id="mCSB_1_container" className="mCSB_container mCS_y_hidden mCS_no_scrollbar_y" style={{ position: 'relative', top: '0', left: '0' }} dir="ltr">
                                        <span className='small mb-2 d-block'>
                                          {idx == 0 ? (<span>{numFormat(val.balance)}&nbsp;{val.name}&nbsp;(${numFormat(val.market_cap)})</span>) :
                                            <>
                                              <i className='fa fa-angle-right text-muted' />
                                              <a href={`/token/${val.token}`}>
                                                {val.balance}&nbsp;{val.name}&nbsp;(${val.market_cap})
                                              </a>
                                            </>
                                          }
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                )
                              })}
                            </div>
                            <div id="mCSB_1_scrollbar_vertical" className="mCSB_scrollTools mCSB_1_scrollbar mCS-minimal-dark mCSB_scrollTools_vertical">
                              <div className="mCSB_draggerContainer">
                                <div id="mCSB_1_dragger_vertical" className="mCSB_dragger"  >
                                  <div className="mCSB_dragger_bar" style={{ lineHeight: '50px' }}></div>
                                  <div className="mCSB_draggerRail">Total:&nbsp;{numFormat(item.market_cap)}</div>
                                </div>
                              </div>
                            </div>
                          </span>
                        </td>
                        <td className="hidden-xs align-top">
                          <i className={`far ${item.notification_type == 0 ? 'fa-bell-slash' : 'fa-envelope'}`} data-toggle="tooltip" title="" data-original-title="Notifications Disabled"></i>
                          &nbsp;{item.notification_type == 0 ? 'None' : 'Email'}
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan="4" className="align-top">
                      <center>
                        <div className="alert alert-secondary mt-4" role="alert">
                          <i className="fal fa-info-circle"></i>
                          <span className="text-dark">You have no addresses on the watch list</span>
                        </div>
                      </center>
                    </td>
                  </tr>
                )}
                {numFormat(grandTotal) > 0 && (<tr>
                  <td className="align-top">
                    <i className='' data-toggle="tooltip" title="" ></i>
                    Grand Total:&nbsp;(@${numFormat(cmpPrice)}/CMP)
                  </td>
                  <td className="align-top" colSpan="4">
                    <i className='' data-toggle="tooltip" title="" ></i>
                    ${numFormat(grandTotal)}
                  </td>
                </tr>)}
              </tbody>
            </table>
          </figure>
        </div>
      </div>
      {showView && <EditWatch data={curWatch} user={user} show={showView} onHide={handleCreateHide} />}
      <Remove data={curWatch} user={user} show={removeView} onHide={handleCreateHideRemove} />
    </div>
  )
}


export default function MyAddress() {
  const userContext = useContext(UserContext);
  const user = userContext.user || {};
  const [show, setShow] = useState(false);
  const [showList, setShowList] = useState(true);

  if (!user.token) {
    return (window.location.href = '/login');
  }

  return (
    <main role="main">
      <div className="container space-1">
        <div className="row">
          <MySideBar value="myaddress" user={user} />
          <div className="col-md-9">
            <div className="card">
              <div className="card-header d-md-flex justify-content-between align-items-center">
                <div className="d-flex flex-wrap">
                  <div className="mr-2 mb-2 mb-md-0">
                    <h2 className="card-header-title text-nowrap mt-1">My Watch List</h2>
                  </div>
                  <div className="mr-2 mb-2 mb-md-0">
                    <div className="position-relative order-1 order-md-2">
                      <a
                        href="#"
                        id="ContentPlaceHolder1_addnew"
                        data-toggle="modal"
                        data-target="#myModal"
                        rel="tooltip"
                        title=""
                        onClick={() => {
                          setShow(!show);
                        }}
                        className="btn btn-xss btn-primary rounded"
                        data-original-title="Add a new address to your watch list"
                      >
                        <i className="fa fa-plus-square fa-lg mr-1" style={{ marginLeft: '-3px', marginBottom: '3px' }}></i>Add
                      </a>
                    </div>
                  </div>
                </div>
                <div className="d-flex ml-auto"></div>
              </div>
              <AddressList user={user} update={show} />
            </div>
          </div>
          <Augment user={user} show={show} onHide={() => setShow(!show)} />
        </div>
      </div>
    </main>
  );
}
