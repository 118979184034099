import { useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import moment from 'moment';

import Pager from '../../components/Pager';
import Loading from '../../components/Loading';
import TimeTD from '../../components/TimeTD';
import React from 'react';

export default function Txs() {
  const [showAs, setShowAs] = useState('Age');
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [state, setState] = useState({
    // isLimitPages: Number(query.p) > 10000,
    body: {
      start: query.p || '1',
      // length:'50',//默认显示为50
      // length:JSON.parse(sessionStorage.getItem('2')),
      length: JSON.parse(sessionStorage.getItem('2')) == null ? '50' : JSON.parse(sessionStorage.getItem('2')),
      field: query.block ? 'block_no' : undefined,
      value: query.block ? query.block : undefined,
    },

  });
  const handleChangePageSize = e => {
    setState({ ...state, body: { ...state.body, length: e.target.value } });
  };

  const tradeListRequest = useRequest(
    body => ({
      url: '/blockBrowser/blockChain/trade/tradeList',
      method: 'post',
      body: JSON.stringify(body),
    }),
    { manual: true },
  );

  useEffect(() => {
    if (!state.isLimitPages) {
      tradeListRequest.run(state.body);
    }
  }, [state]);

  if (state.isLimitPages) {
    return window.location.replace('/error');
  }

  if (tradeListRequest.loading) {
    return <Loading />;
  }

  const changeType = () => {
    let str = showAs == 'Age' ? 'Date Time (UTC)' : 'Age'
    setShowAs(str)
  }

  const data = tradeListRequest.data?.trade_list || [];
  const counts = tradeListRequest.data?.counts || 0;
  const trade_counts = tradeListRequest.data?.trade_counts || 0;
  const length = state.body.length;
  window.sessionStorage.setItem('2', JSON.stringify(state.body.length))


  const totalPage = Math.ceil(Number(trade_counts) / state.body.length);
  // console.log(totalPage)
  // console.log(state.body.length)
  // console.log(trade_counts)
  return (
    <main role="main">
      <input type="hidden" name="hdnAgeText" value="Age" />
      <input type="hidden" name="hdnDateTimeText" value="Date Time (UTC)" />
      <input type="hidden" name="hdnAgeTitle" value="Click to show Age Format" />
      <input type="hidden" name="hdnDateTimeTitle" value="Click to show Datetime Format" />
      <div className="container">
        <div className="d-md-flex justify-content-between align-items-center py-3">
          <div className="mb-1 mb-md-0">
            <h1 className="h4 mb-0">
              Transactions&nbsp;<span className="small text-secondary"></span>
              {query.block ? (
                <p className="mb-0 text-break small">
                  <span className="small text-secondary">
                    <span>For Block </span>
                    <a href={`/block/${query.block}`}>
                      <span className="text-primary text-break">{query.block}</span>
                    </a>
                  </span>
                </p>
              ) : undefined}
            </h1>
          </div>
        </div>
      </div>
      <div className="container space-bottom-2">
        <span></span>
        <div className="card">
          <div className="card-body">
            <div className="d-flex flex-wrap flex-xl-nowrap text-nowrap align-items-center ml-auto"></div>
            <div className="d-md-flex justify-content-between mb-4">
              {query.block ? (
                <p className="mb-2 mb-md-0">
                  <span className="d-flex align-items-center">
                    <i className="fa fa-spin fa-spinner fa-1x fa-pulse mr-1" style={{ display: 'none' }}></i>A total of {counts} transactions found
                  </span>
                </p>
              ) : (
                <p className="mb-2 mb-md-0">
                  <span className="d-flex align-items-center">More than &gt; {counts} transactions found</span>
                  <span className="d-block small">(Showing the last {trade_counts} records)</span>
                </p>
              )}
              <Pager current={state.body.start} total={totalPage} path="/txs" />
            </div>
            <div className="table-responsive mb-2 mb-md-0">
              <table className="table table-hover">
                <thead className="thead-light">
                  <tr>
                    <th scope="col" width="20"></th>
                    <th scope="col" width="1">
                      Txn Hash
                    </th>
                    <th scope="col" width="1" className="d-none d-sm-table-cell">
                      <div className="d-flex align-items-center">
                        <div className="mr-2">Block</div>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="d-flex align-items-center">
                        <div className="mr-2">
                          <a href="#" data-toggle-commonjs="tooltip" data-original-title="Click to show Datetime Format" onClick={changeType}>
                            {showAs}
                          </a>
                        </div>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="d-flex align-items-center">
                        <div className="mr-2">From</div>
                      </div>
                    </th>
                    <th scope="col" width="30"></th>
                    <th scope="col">
                      <div className="d-flex align-items-center">
                        <div className="mr-2">To</div>
                      </div>
                    </th>
                    <th scope="col">Value</th>
                    <th scope="col">
                      <span className="text-secondary" title="" data-toggle="tooltip" data-original-title="(Gas Price * Gas Used by Txn) in CMP">
                        [Txn Fee]
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {React.Children.toArray(
                    data.map(item => {
                      const time = moment(item.create_time).startOf('minute').fromNow();

                      return (
                        <tr>
                          <td>
                            <a role="button" tabIndex="0" type="button" className="js-txnAdditional-1 btn btn-xs btn-icon btn-soft-secondary myFnExpandBox">
                              <i className="far fa-eye btn-icon__inner"></i>
                            </a>
                          </td>
                          <td>
                            {item.trade_status === 0 ? (
                              <span className="text-danger" data-toggle="tooltip" title="" data-original-title="Error in Main Txn : execution reverted">
                                <strong>
                                  <i className="fa fa-exclamation-circle"></i>
                                </strong>
                              </span>
                            ) : undefined}
                            <span className="hash-tag text-truncate">
                              <a href={`/tx/${item.trade_hash}`} className="myFnExpandBox_searchVal">
                                {item.trade_hash}
                              </a>
                            </span>
                          </td>
                          <td className="d-none d-sm-table-cell">
                            <a href={`/block/${item.block_no}`}>{item.block_no}</a>
                          </td>
                          <TimeTD time={item.trade_time} interval={item.trade_interval_time} showAs={showAs} />
                          <td>
                            <span className="hash-tag text-truncate" data-toggle="tooltip" title="" data-original-title={item.trade_from}>
                              <a href={`/address/${item.trade_from}`}>{item.trade_from}</a>
                            </span>
                          </td>
                          <td className="text-center">
                            <span className="btn btn-xs btn-icon btn-soft-success rounded-circle">
                              <i className="fas fa-long-arrow-alt-right btn-icon__inner"></i>
                            </span>
                          </td>
                          <td>
                            <span style={{ whiteSpace: 'nowrap' }}>
                              <i className="far text-secondary" data-toggle="tooltip" title="" data-original-title="Contract" className={item.trade_to_sign == true ? 'far fa-file-alt' : ''}></i>{" "}
                              <span className="hash-tag text-truncate" data-toggle="tooltip" title="" data-original-title={item.trade_to}>
                                <a href={`/address/${item.trade_to}`}>{item.trade_to}</a>
                              </span>
                            </span>
                          </td>
                          <td>{item.trade_amount} CMP</td>
                          <td>
                            <span className="small text-secondary">{item.trade_fee}</span>
                          </td>
                        </tr>
                      );
                    }),
                  )}
                </tbody>
              </table>
            </div>
            <div>
              <div className="d-md-flex justify-content-between my-3">
                <div className="d-flex align-items-center text-secondary mb-2 mb-md-0">
                  Show
                  <select className="custom-select custom-select-xs mx-2" onChange={handleChangePageSize} defaultValue={length}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  Records
                </div>
                <Pager current={state.body.start} total={totalPage} path="/txs" />
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3 mb-4">
              <div className="d-flex align-items-center text-secondary"></div>
              <div className="small text-muted"></div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
