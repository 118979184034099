import { useState, useContext, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useRequest } from 'ahooks';
import Modal from 'react-bootstrap/Modal';
import MySideBar from '../../components/MySideBar';
import UserContext from '../../UserContext';
import { Button, Form, Input, message, Tooltip } from 'antd';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

function OverviewTab({ onChange }) {
  const userContext = useContext(UserContext);
  const user = userContext.user || {};
  const updateUser = userContext.updateUser || undefined

  return (
    <div className="tab-pane fade active show" role="tabpanel" aria-labelledby="overview-tab">
      <p className="ml-2">Below are the username, email and overview information for your account.</p>
      <div className="table-responsive py-2">
        <div className="mx-md-auto">
          <figure className="mb-7 ie-data-report">
            <table className="table">
              <tbody>
                <tr>
                  <td>
                    <div className="py-2">Your Username :</div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-between">
                      <div className="p-2">{user.user_name}</div>
                      <div className="p-2"></div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="py-2">Your Email Address :</div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-between">
                      <div className="p-2">{user.email}</div>
                      <div className="p-2">
                        <a
                          href="#profile"
                          className="js-tab-external-link"
                          data-type="static"
                          onClick={e => {
                            onChange({ action: 'switchTab' });
                          }}
                        >
                          <i className="fas fa-pencil-alt"></i>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="py-2">Address Watch List :</div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-between">
                      <div className="p-2">{user.address_watch_count || '0'} Address Alert(s)</div>
                      <div className="p-2">
                        <a href="myaddress">
                          <i className="fa fa-search-plus"></i>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="py-2">Transaction Notes :</div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-between">
                      <div className="p-2">{user.txn_notes_count || '0'} out of {user.txn_notes_count_limit || '0'} available limit</div>
                      <div className="p-2">
                        <a href="mynotes_tx">
                          <i className="fa fa-search-plus"></i>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="py-2">Address Tags :</div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-between">
                      <div className="p-2">{user.address_tags_count || '0'} out of {user.address_tags_count_limit || '0'} available limit</div>
                      <div className="p-2">
                        <a href="mynotes_address">
                          <i className="fa fa-search-plus"></i>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="py-2">Email Notification Limit :</div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-between">
                      <div className="p-2">{user.email_send_day_count} emails sent out {user.email_send_day_count_limit} daily limit available</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="py-2">Total CMP Balance :</div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-between">
                      <div className="p-2">
                        {user.total_balance || '0'} CMP<span> @ (${user.total_value || '0'})</span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="py-2">Last Login :</div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-between">
                      <div className="p-2">{user.last_login_time} (UTC)</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </figure>
        </div>
      </div>
    </div>
  );
}

function ChangeProfilePicture({ value, onChange, show, onHide, handleContinues }) {
  const userContext = useContext(UserContext);
  const user = userContext.user || {};
  const [state, setState] = useState({
    file: '',
    imagePreviewUrl: '',
  });
  const uploadFileRequest = useRequest(
    body => {
      const formData = new FormData();
      formData.append('upfile', body.upfile);
      return {
        url: '/blockBrowser/user/userInfo/uploadFile',
        method: 'post',
        headers: {
          // 'Content-Type': 'multipart/form-data',
          Authorization: user.token,
        },
        body: formData,
      };
    },
    { manual: true, formatResult: r => r },

  );


  const handleUploadFile = e => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    console.log(file, reader.result)
    reader.onloadend = () => {
      setState({
        file: e.target.files[0],
        imagePreviewUrl: reader.result,
      })
    }
    try {
      reader.readAsDataURL(file);
    } catch (error) {
      console.log(error)
    }

  };
  const handleContinue = () => {
    if (state.file) {
      // 上传文件
      uploadFileRequest.run({ upfile: state.file }).then(res => {
        onChange(res.data);
        onHide();
      });
    } else {
      onHide();
    }
    handleContinues(state.imagePreviewUrl)
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className="p-2">
        <Modal.Title>Change your Public Profile Picture type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group" style={{ marginLeft: '-15px' }}>
          <div
            className="custom-control custom-radio form-check-inline"
            style={{ marginBottom: '15px' }}
            onClick={e => {
              onChange(1);
            }}
          >
            <input
              type="radio"
              value="RadioButton5"
              checked={value == 1 ? 'checked' : ''}
              onChange={e => {
                console.log(e);
              }}
            />
            <label className="radio" htmlFor="ContentPlaceHolder1_RadioButton5">
              &nbsp;
              <span>
                <span className="btn btn-icon btn-md btn-soft-success ml-1 mr-1 rounded-circle" data-toggle="tooltip" title="" data-original-title="Your Avatar Profile">
                  <span className="btn-icon__inner">{user && user.user_name && user.user_name.split('')[0].toUpperCase()}</span>
                </span>
              </span>
              &nbsp;System assigned profile picture&nbsp;
            </label>
          </div>
          <div
            className="custom-control custom-radio form-check-inline"
            style={{ marginBottom: '16px' }}
            onClick={e => {
              onChange(2);
            }}
          >
            <input
              type="radio"
              value="RadioButton6"
              checked={value == 2 ? 'checked' : ''}
              onChange={e => {
                console.log(e);
              }}
            />
            <label className="radio" htmlFor="ContentPlaceHolder1_RadioButton6">
              &nbsp;
              <span>
                <img
                  className="u-avatar rounded-circle ml-1 mr-1"
                  src='/images/avt.png'
                />
              </span>
              &nbsp;Blockies Type Profile Picture&nbsp;
            </label>
          </div>
          <div className="custom-control custom-radio form-check-inline"
            style={{ marginBottom: '17px' }}
            onClick={e => {
              onChange(3);
            }}
          >
            <span className="aspNetDisabled">
              <input
                type="radio"
                value="RadioButton7"
                checked={value == 3 ? 'checked' : ''}
                onChange={e => {
                  console.log(e);
                }}
              />
            </span>
            <label className="radio" htmlFor="ContentPlaceHolder1_RadioButton7">
              &nbsp;
              <span style={{ position: 'relative' }}>
                {/* <img src={imgUrl} /><strong>X</strong> */}
                <span
                  className="customImgRemoveBtn"
                  data-toggle="modal"
                  data-target="#removePictureModal"
                  style={{
                    position: 'absolute',
                    width: '20px',
                    height: '20px',
                    left: '30px',
                    top: '-15px',
                    borderRadius: '15px',
                    border: '1px solid lightgrey',
                    textAlign: 'center',
                    background: '#fff',
                    WebkitBoxShadow: '1px 4px 5px 0px rgba(0, 0, 0, 0.43)',
                    boxShadow: '1px 4px 5px 0px rgba(0, 0, 0, 0.43)',
                    visibility: 'hidden',
                    color: '#3e444a',
                  }}
                >
                  <strong>X</strong>
                </span>
                <span></span>
                <span>
                  &nbsp;Custom Pic&nbsp;<i className="fa fa-spin fa-spinner fa-1x fa-pulse mr-1" style={{ display: 'none' }}></i>
                  <input type="file" id="selectPic" style={{ display: 'none' }} title="Recommended size of 128 x128 px and less than 100kb" onChange={handleUploadFile} accept=".png,.jpg,.svg" />
                  <input type="button" value={'Select pic'} className="ml-1 btn btn-xss btn-primary" title="Recommended size of 128 x128 px and less than 100kb" onClick={() => { document.getElementById('selectPic').click() }} accept=".png,.jpg,.svg" />
                  <span style={{ marginLeft: '10px' }}>{state.file.name}</span>
                </span>
              </span>
            </label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button data-dismiss="modal"
          className="btn btn-sm btn-secondary btn-xs"
          type="button" causesvalidation="False" onClick={onHide}>
          Cancel
        </button>
        <input type="button"
          value="Continue"
          className="btn btn-sm btn-primary btn-xs" onClick={handleContinue} />
      </Modal.Footer>
    </Modal>
  );
}

function ProfileTab() {
  const userContext = useContext(UserContext);
  const user = userContext.user || {};
  const [showChangeProfilePicture, setShowChangeProfilePicture] = useState(false);
  const [showConfirmPwd, setShowConfirmPwd] = useState(false);
  const [showConfirmDel, setShowConfirmDel] = useState(false);
  const [errors, setErrors] = useState({});
  const [nameError, setNameError] = useState({});
  const [editResult, setEditResult] = useState({});
  const [form, setForm] = useState({
    email_address: '',
    password: '',
    old_password: '',
    new_password: '',
    confirm_password: '',
    profile_info: {},
    newsletter: 1, // 0: 未订阅, 1: 已订阅, 2: 已取消
    newsletter_cancel: 0,
    newsletter_cancel_content: '',
  });
  const [state, setState] = useState({ image: '' })

  useEffect(() => {
    console.log(user, 'user')
    let _form = form
    let profile = {
      public_profile_name: user && user.profile_info && user.profile_info.public_profile_info,
      public_profile_picture: user && user.profile_info && user.profile_info.public_profile_picture,
      public_profile_picture_type: user && user.profile_info && user.profile_info.public_profile_picture_type, // 1: 系统，2: 锁定， 3: 上传
      public_profile_bio: user && user.profile_info && user.profile_info.public_profile_bio,
      profile_website: user && user.profile_info && user.profile_info.profile_website,
    }
    setForm({ ..._form, email_address: user.email, profile_info: profile, newsletter: user.newsletter, newsletter_cancel: user.newsletter === 2 ? 1 : 0 })
  }, [user])
  const userEditRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/userInfo/userEdit',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );
  const hideChangeProfilePicture = () => {
    setShowChangeProfilePicture(false);
  };

  const hideConfirmPwd = () => {
    setShowConfirmPwd(false)
  }

  const hideConfirmDel = () => {
    setShowConfirmDel(false)
  }

  const handleChangeProfilePicture = e => {
    if (typeof e === 'number') {
      handleProfileInfoField('public_profile_picture_type')({ target: { value: e } });
    } else {
      handleProfileInfoField('public_profile_picture_type')({ target: { value: 3 } });
      handleProfileInfoField('public_profile_picture')({ target: { value: e + '' } });
    }
  };

  const handleToggleNewsletter = type => e => {
    setForm({ ...form, newsletter_cancel: type });
  };

  const handleField = field => e => {
    setForm({ ...form, [field]: e.target.value });

    if (e.target.value) {
      setErrors({ ...errors, [field]: undefined });
    }
  };

  const handleBlur = key => () => {
    // const emailREG = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/gi;
    const emailREG = /^(?=\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$).{0,64}$/;
    const _errors = { ...errors };

    Object.keys(form)
      .filter(f => f !== 'newsletter')
      .forEach(field => {
        if (field === key) {
          _errors[field] = !form[field];
        }
      });

    if (form.old_password && form.old_password.length < 5 && key === 'old_password') {
      _errors.old_password = true;
    }
    if (form.new_password && form.new_password.length < 5 && key === 'new_password') {
      _errors.new_password = true;
    }
    if (form.new_password && form.old_password && form.new_password === form.old_password && key === 'new_password') {
      _errors.new_password = 'repetition'
    }

    if (form.new_password && form.confirm_password && form.new_password !== form.confirm_password && key === 'confirm_password') {
      _errors.confirm_password = 'equalto';
    }

    if (!emailREG.test(form.email_address) && key === 'email_address') {
      _errors.email_address = true;
    }


    if (Object.keys(_errors).some(e => !!_errors[e])) {
      if (_errors[key]) {
        setErrors(_errors);
      }
    }
  }



  const handleProfileInfoField = field => e => {
    setForm({
      ...form,
      profile_info: {
        ...form.profile_info,
        [field]: e.target.value,
      },
    });

    if (e.target.value) {
      setErrors({ ...errors, [field]: undefined });
    }
  };

  const handleProfileInfoBlur = key => () => {
    const nameReg = /^[a-zA-Z0-9_.\-\s]{0,30}$/;
    const _nameError = { ...nameError };

    if (!nameReg.test(form.profile_info.public_profile_name) && key == 'public_profile_name') {
      _nameError.public_profile_name = true;
      setNameError(_nameError);
    }
  };

  const handleSubmit = () => {
    if (form.email_address != user.email) {
      setShowConfirmPwd(true)
    } else {
      userEditRequest.run(form).then(res => {
        if (res.code == 0) {
          setEditResult(res);
          // message.success('Account information updated successfully')
          setTimeout(() => {
            window.location.reload()
          }, 1000);
        }
      });
    }
  };

  const getNewPwd = (val) => {
    Object.assign(form, { password: val })
    setShowConfirmPwd(false)
    userEditRequest.run(form).then(res => {
      if (res.code == 0) {
        setEditResult(res);
        // message.success('Account information updated successfully')
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      }
    });
  }

  const handleCancel = () => { };

  const handleDelete = e => {
    e.preventDefault();
    setShowConfirmDel(true)
  };

  var handleContinues = (img) => {
    setState({ image: img })
  }

  const showError = typeof userEditRequest.data?.code !== 'undefined' && userEditRequest.data?.code !== 0;

  return (
    <div className="tab-pane fade active show" role="tabpanel" aria-labelledby="profile-tab">
      <span></span>
      {editResult.code === 0 ? (
        <div className="alert alert-success">
          <strong>Status:</strong> Account information updated successfully
        </div>
      ) : undefined}

      <div className="py-2">
        <div className="w-md-80 mx-md-auto">
          <figure className="mb-7 ie-data-report">
            <form method="post" className="js-validate mx-md-auto" autoComplete="off" noValidate="noValidate">
              <div className="alert alert-danger" style={{ display: showError ? 'block' : 'none' }}>
                {userEditRequest.data?.enMsg}
              </div>
              <h3 className="small text-muted mt-3">YOUR USER LOGIN SETTINGS</h3>
              <div className="form-group row mt-4" style={{ marginBottom: '7px' }}>
                <label className="col-sm-4 text-nowrap" htmlFor="Username">
                  <b>Username</b>
                </label>
                <div className="col-sm-8">{user.user_name}</div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 text-nowrap align-self-center mb-sm-0" htmlFor="Emailaddress">
                  Email address
                </label>
                <div
                  className="js-form-message input-group input-group-sm col-sm-8"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  data-title="You will need to reconfirm your email address if changed"
                  data-trigger="focus"
                  data-original-title=""
                  title=""
                >
                  <input
                    value={form.email_address}
                    placeholder="Email address"
                    className="form-control"
                    type="email"
                    required=""
                    data-msg="Please enter your valid email address"
                    data-error-class="u-has-error"
                    data-success-class="u-has-success"
                    onChange={handleField('email_address')}
                    onBlur={handleBlur('email_address')}
                  />
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <span className="fa fa-lg fa-envelope"></span>
                    </span>
                  </div>
                  <div className="invalid-feedback" style={{ display: errors.email_address ? 'block' : 'none', textAlign: 'center' }}>
                    Please enter a valid email address.
                  </div>
                </div>

              </div>
              <div className="form-group row">
                <label className="col-sm-4 text-nowrap align-self-center mb-sm-0" htmlFor="Oldpassword">
                  Enter OLD password
                </label>
                <div
                  className="input-group input-group-sm col-sm-8"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  data-title="Leave the password box empty if no changes"
                  data-trigger="focus"
                  data-original-title=""
                  title=""
                >
                  <input type="password"
                    placeholder="Password"
                    className="form-control"
                    value={form.old_password}
                    onChange={handleField('old_password')}
                    onBlur={handleBlur('old_password')} />
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <span className="fa fa-lg fa-lock"></span>
                    </span>
                  </div>
                  <div className="invalid-feedback" style={{ display: errors.old_password ? 'block' : 'none', textAlign: 'center' }}>
                    Your password must be at least 5 characters long.
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 text-nowrap align-self-center mb-sm-0" htmlFor="Newpassword">
                  Enter NEW password
                </label>
                <div
                  className="input-group input-group-sm col-sm-8"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  data-title="Leave the password box empty if no changes"
                  data-trigger="focus"
                  data-original-title=""
                  title=""
                >
                  <input type="password"
                    placeholder="Password"
                    className="form-control"
                    value={form.new_password}
                    onChange={handleField('new_password')}
                    onBlur={handleBlur('new_password')} />
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <span className="fa fa-lg fa-lock"></span>
                    </span>
                  </div>
                  <div className="invalid-feedback" style={{ display: errors.new_password ? 'block' : 'none', textAlign: 'center' }}>
                    {errors.new_password === 'repetition' ? 'The new password cannot be the same as the old password' : 'Your password must be at least 5 characters long.'}
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 text-nowrap align-self-center" htmlFor="Confirmnewpassword">
                  Re-Confirm password
                  <span style={{ color: 'red', display: 'none' }}>**</span>
                  <span style={{ color: 'red', fontSize: 'smaller', display: 'none' }}>
                    <br />
                    Passwords do not match
                  </span>
                </label>
                <div
                  className="input-group input-group-sm col-sm-8 mb-4"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  data-title="Re-enter your password confirmation if you changed your password above"
                  data-trigger="focus"
                  data-original-title=""
                  title=""
                >
                  <input
                    type="password"
                    placeholder="Confirm password"
                    className="form-control"
                    value={form.confirm_password}
                    onChange={handleField('confirm_password')}
                    onBlur={handleBlur('confirm_password')}
                  />
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <span className="fa fa-lg fa-lock"></span>
                    </span>
                  </div>
                  <div className="invalid-feedback" style={{ display: errors.confirm_password ? 'block' : 'none' }}>
                    {errors.confirm_password === 'equalto' ? 'Password does not match, please check again.' : 'Your password must be at least 5 characters long.'}
                  </div>
                </div>
              </div>
              <hr className="mt-3 mb-4" />
              <h3 className="small text-muted">
                Caduceus PROFILE INFO
                <span className="float-right u-label u-label--secondary font-size-1 rounded py-1 px-2" style={{ marginTop: '-4px' }} title="Profile Status/Level">
                  Status: User
                </span>
              </h3>
              <div className="form-group row mt-4">
                <label className="col-sm-4 text-nowrap align-self-center mb-sm-0" htmlFor="">
                  <Tooltip title="Your PUBLIC Profile information can be viewed publicly.Please do not enter sensitive information like your private keys here.">
                    <b>
                      Public Profile Name <i className="text-success far fa-question-circle"></i>
                    </b>
                    <span style={{ color: 'red', fontSize: 'smaller', display: 'none' }}>
                      <br />
                      *Min of 7 characters, no spaces
                    </span>
                  </Tooltip>
                </label>
                <div
                  className="input-group input-group-sm col-sm-8"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  data-title="This is your public user profile name (Alphanumeric with minimum of 7 and not more than 30 characters)."
                  data-trigger="focus"
                  data-original-title=""
                  title=""
                >
                  <input
                    maxLength="30"
                    type="text"
                    autoComplete="new-password"
                    placeholder="Public user profile name"
                    className="form-control"
                    value={form.profile_info.public_profile_name}
                    onChange={handleProfileInfoField('public_profile_name')}
                    onBlur={handleProfileInfoBlur('public_profile_name')}
                  />
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <span className="fas fa-lg fa-user-alt"></span>
                    </span>
                  </div>
                  <div className="invalid-feedback" style={{ display: nameError.public_profile_name ? 'block' : 'none', textAlign: 'center' }}>
                    Please enter a valid Public user profile name.
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 text-nowrap align-self-center mb-sm-0" htmlFor="">
                  Public Profile Picture
                </label>
                <div className="input-group input-group-sm col-sm-8">
                  <span>
                    {
                      form.profile_info.public_profile_picture_type === 1 ?
                        <span className="btn btn-icon btn-md btn-soft-success ml-1 mr-1 rounded-circle" data-toggle="tooltip" title="" data-original-title="Your Avatar Profile">
                          <span className="btn-icon__inner">{user.user_name && user.user_name.split('')[0].toUpperCase()}</span>
                        </span>
                        : form.profile_info.public_profile_picture_type === 2 ?
                          <img
                            className="u-avatar rounded-circle ml-1 mr-1"
                            src={'/images/avt.png'}
                          />
                          : form.profile_info.public_profile_picture_type === 3 ?
                            <img
                              className="u-avatar rounded-circle ml-1 mr-1"
                              src={state.image ? state.image : user.user_name && user.profile_info.public_profile_picture}
                            />
                            : null
                    }
                  </span>
                  <div style={{ marginTop: '4px' }}>
                    <a
                      href="#"
                      data-toggle="modal"
                      rel="tooltip"
                      data-target="#myModal"
                      title=""
                      className="ml-1 btn btn-xss btn-primary"
                      data-original-title="Select your Profile Picture Type"
                      onClick={e => {
                        e.preventDefault();
                        setShowChangeProfilePicture(true);
                      }}
                    >
                      <span>Select</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 text-nowrap align-self-center mb-sm-0" htmlFor="Oldpassword">
                  Public Profile Bio
                </label>
                <div
                  className="input-group input-group-sm col-sm-8"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  data-title="Tell us more about yourself (Up to 500 characters)"
                  data-trigger="focus"
                  data-original-title=""
                  title=""
                >
                  <input
                    maxLength="500"
                    type="text"
                    placeholder="Public bio profile"
                    className="form-control"
                    value={form.profile_info.public_profile_bio}
                    onChange={handleProfileInfoField('public_profile_bio')}
                    onBlur={handleProfileInfoBlur('public_profile_bio')}
                  />
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <span className="fa-lg far fa-book-user"></span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 text-nowrap align-self-center" htmlFor="Oldpassword">
                  Profile Website
                  <span style={{ color: 'red', fontSize: 'smaller', display: 'none' }}>
                    <br />
                    *Incorrect format or missing https://
                  </span>
                </label>
                <div
                  className="input-group input-group-sm col-sm-8 mb-3"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  data-html="true"
                  data-title="Your website URL including the https or https <br>(i.e https://yourdomain.com )"
                  data-trigger="focus"
                  data-original-title=""
                  title=""
                >
                  <input
                    maxLength="155"
                    type="text"
                    placeholder="https://yourwebsite.com"
                    className="form-control"
                    value={form.profile_info.profile_website}
                    onChange={handleProfileInfoField('profile_website')}
                    onBlur={handleProfileInfoBlur('profile_website')}
                  />
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <span className="fa-lg fas fa-globe"></span>
                    </span>
                  </div>
                </div>
              </div>
              <hr className="mt-3 mb-4" />
              <h3 className="small text-muted">MONTHLY NEWSLETTER SUBSCRIPTION AND GIVEAWAYS</h3>
              <div className="form-group row mt-4">
                <label className="col-sm-4" htmlFor="Description">
                  Newsletter
                </label>
                <div className="col-sm-8">
                  <div
                    className="custom-control custom-checkbox d-flex align-items-center"
                    onClick={() => {
                      let _form = form
                      setForm({ ..._form, newsletter: _form.newsletter === 1 ? 2 : 1, newsletter_cancel: _form.newsletter === 1 ? 1 : 0 })
                    }}
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      data-toggle="tooltip"
                      title=""
                      checked={form.newsletter === 1 ? 'checked' : ''}
                      data-original-title="Subscribe for Newsletter"
                      onChange={e => {
                        console.log(e);
                      }}
                    />
                    <label className="custom-control-label" htmlFor="ContentPlaceHolder1_chkNewsletter">
                      (click to Subscribe for Newsletter)
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group row" style={{ display: form.newsletter === 2 ? undefined : 'none' }}>
                <div className="col-sm-4"></div>
                <div className="col-sm-8">
                  <div className="mb-2">
                    <p>Please let us know why you unsubscribed:</p>
                  </div>
                  <div className="custom-control custom-radio d-flex align-items-center mb-1" onClick={handleToggleNewsletter(1)}>
                    <input
                      value="RadioButton0"
                      type="radio"
                      className="custom-control-input"
                      checked={form.newsletter_cancel === 1 ? 'checked' : ''}
                      onChange={e => {
                        console.log(e);
                      }}
                    />
                    <label className="custom-control-label" htmlFor="ContentPlaceHolder1_RadioButton0">
                      I no longer want to receive these emails
                    </label>
                  </div>
                  <div className="custom-control custom-radio d-flex align-items-center mb-1" onClick={handleToggleNewsletter(2)}>
                    <input
                      value="RadioButton1"
                      type="radio"
                      className="custom-control-input"
                      checked={form.newsletter_cancel === 2 ? 'checked' : ''}
                      onChange={e => {
                        console.log(e);
                      }}
                    />
                    <label className="custom-control-label" htmlFor="ContentPlaceHolder1_RadioButton1">
                      I never signed up for this mailing list
                    </label>
                  </div>
                  <div className="custom-control custom-radio d-flex align-items-center mb-1" onClick={handleToggleNewsletter(3)}>
                    <input
                      value="RadioButton2"
                      type="radio"
                      className="custom-control-input"
                      checked={form.newsletter_cancel === 3 ? 'checked' : ''}
                      onChange={e => {
                        console.log(e);
                      }}
                    />
                    <label className="custom-control-label" htmlFor="ContentPlaceHolder1_RadioButton2">
                      The emails are inappropriate
                    </label>
                  </div>
                  <div className="custom-control custom-radio d-flex align-items-center mb-1" onClick={handleToggleNewsletter(4)}>
                    <input
                      value="RadioButton3"
                      type="radio"
                      className="custom-control-input"
                      checked={form.newsletter_cancel === 4 ? 'checked' : ''}
                      onChange={e => {
                        console.log(e);
                      }}
                    />
                    <label className="custom-control-label" htmlFor="ContentPlaceHolder1_RadioButton3">
                      The emails are spam and should be reported
                    </label>
                  </div>
                  <div className="custom-control custom-radio d-flex align-items-center mb-1" onClick={handleToggleNewsletter(5)}>
                    <input
                      value="RadioButton4"
                      type="radio"
                      className="custom-control-input"
                      checked={form.newsletter_cancel === 5 ? 'checked' : ''}
                      onChange={e => {
                        console.log(e);
                      }}
                    />
                    <label className="custom-control-label" htmlFor="ContentPlaceHolder1_RadioButton4">
                      Other
                    </label>
                  </div>
                </div>
              </div>
              <hr className="mt-3 mb-4" />
              <br />
              <div className="d-flex justify-content-start mb-2">
                <div className="mb-2">
                  <input type="button" onClick={handleSubmit} value="Save Changes" className="btn btn-sm btn-primary btn-xs mr-2" />
                </div>

                <div className="mb-2" style={{ visibility: 'hidden' }}>
                  <input type="button" onClick={handleCancel} value="Cancel" className="btn btn-sm btn-secondary btn-xs mr-2 cancel" />
                </div>

                <div className="ml-auto">
                  <a href="#" className="btn btn-danger btn-xs" onClick={handleDelete}>
                    <i className="far fa-trash-alt" data-toggle="tooltip" title="" data-original-title="Delete Account"></i>&nbsp; Delete Account
                  </a>
                </div>
              </div>
            </form>
          </figure>
        </div>
      </div>
      <ConfirmPwdModal
        show={showConfirmPwd}
        onHide={hideConfirmPwd}
        value={form.password}
        submit={getNewPwd}
      />
      <ChangeProfilePicture show={showChangeProfilePicture}
        user={user}
        handleContinues={handleContinues}
        onHide={hideChangeProfilePicture}
        value={form.profile_info.public_profile_picture_type}
        onChange={handleChangeProfilePicture} />
      <ConfirmPDelModal
        user={user}
        show={showConfirmDel}
        onHide={hideConfirmDel}
      />
    </div>
  );
}

function ConfirmPDelModal({ show, onHide, user }) {

  const [flag, setFlag] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const userDelRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/userInfo/deleteUser',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    { manual: true, formatResult: r => r },
  );

  const handleDelete = async () => {
    const token = await executeRecaptcha('login');
    userDelRequest.run({ recaptcha_token: token }).then(res => {
      if (res.code != 0) {
        message.error(res.enMsg);
      } else {
        setFlag(true)
      }
    })
  }
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className="p-2">
        <Modal.Title>Confirmation Required</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {(show && !flag) && <p>
          <b>Are you sure you want to permanently delete your user account?</b>
          <br />
          Deleting your user account will also delete all the watchlists, transaction notes, private tags and verified addresses ownership. Recovery of the above is not possible upon delete confirmation.
        </p>}
        {flag && <p>
          We have sent an email with a confirmation link to your registered email address. Please confirm this request by clicking on the confirmation link.
          <br />
          <br />
          The link will expire in 1 hour. If you do not receive a confirmation email, please check your spam folder and ensure your spam filters allow emails from noreply@CMPScan.com.
        </p>}
      </Modal.Body>
      <Modal.Footer>
        {/* delete-account */}
        {(show && !flag) && <>
          <Button onClick={onHide} className='mr-2'>
            Cancel
          </Button>
          <Button type="primary" onClick={handleDelete}>
            Confirm Delete
          </Button>
        </>}
        {flag && <Button onClick={onHide} className='mr-2'>
          Close
        </Button>}
      </Modal.Footer>
    </Modal>
  )

}

function ConfirmPwdModal({ show, onHide, value, submit }) {
  const handleSubmitPwd = () => {
    submit(value)
  }
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className="p-2">
        <Modal.Title>Password Required</Modal.Title>
      </Modal.Header>
      <Form layout="vertical" onFinish={handleSubmitPwd}>
        <Modal.Body>
          <Form.Item label="Please enter your password to confirm the request." name="password" rules={[{ required: true, message: 'Your password is invalid. Please try again.' }]}>
            <Input.Password onChange={e => { value = e.target.value }} />
          </Form.Item>
        </Modal.Body>
        <Modal.Footer>
          <Form.Item>
            <Button onClick={onHide} className='mr-2'>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default function MyAccount() {
  const userContext = useContext(UserContext);
  const user = userContext.user || {};

  const location = useLocation();
  // profile overview
  const [currentTab, setCurrentTab] = useState('profile');

  if (user.token == 'undefined') {
    window.location.href = '/login'
  }

  return (
    <main role="main">
      <div className="container space-1">
        <div className="row">
          <MySideBar value="myaccount" user={user} />
          <div className="col-md-9">
            <div className="card">
              <div className="card-header d-md-flex justify-content-between align-items-center p-0">
                <ul className="nav nav-custom nav-borderless" role="tablist">
                  <li
                    className="nav-item"
                    onClick={() => {
                      setCurrentTab('overview');
                    }}
                  >
                    <a className={`nav-link ${currentTab === 'overview' ? 'active' : ''}`} data-toggle="tab" href="#overview" role="tab" aria-controls="overview" aria-selected="true">
                      Overview
                    </a>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => {
                      user.user_name && setCurrentTab('profile');
                    }}
                  >
                    <a className={`nav-link ${currentTab === 'profile' ? 'active' : ''}`} data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">
                      Account Settings &amp; Profile
                    </a>
                  </li>
                </ul>
              </div>
              <div className="card-body">
                <div className="tab-content">
                  {currentTab === 'overview' ? (
                    <OverviewTab
                      onChange={e => {
                        if (e.action === 'switchTab') {
                          setCurrentTab('profile');
                        }
                      }}
                    />
                  ) : undefined}

                  {currentTab === 'profile' ? <ProfileTab /> : undefined}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
