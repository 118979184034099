import { useState, useContext, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { MinusCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space, message, Select } from 'antd';
import { LeftOutlined } from "@ant-design/icons";
import { useRequest } from 'ahooks';
import Modal from 'react-bootstrap/Modal';
import UserContext from '../../UserContext';

import './index.css'
const { Option } = Select;

function RejectCon({ data = {}, user, show, onHide }) {
    const auditTagRequest = useRequest(
        body => ({
            url: '/blockBrowser/audit/addressTag/audit',
            method: 'post',
            headers: {
                Authorization: user.token,
            },
            body: JSON.stringify(body),
        }),
        { manual: true, formatResult: r => r },
    );
    const handleCurReject = (value) => {
        auditTagRequest.run({ id: data.id, verified: 2, reject_reason: value.reject_reason }).then(res => {
            if (res.code == 0) {
                onHide()
            } else {
                message.error(res.enMsg)
            }
        })
    };
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton className="p-2">
                <Modal.Title>Reason for rejection</Modal.Title>
            </Modal.Header>
            <Form onFinish={handleCurReject}>
                <Modal.Body>
                    <Form.Item name="reject_reason" rules={[{ required: true, message: 'Please input your rejection!' }]}>
                        <Input.TextArea />
                    </Form.Item>
                </Modal.Body>
                <Modal.Footer style={{ justifyContent: 'center' }}>
                    <Form.Item>
                        <Button htmlType="button" onClick={onHide} className='mr-3'>Cancel</Button>
                        <Button type="primary" htmlType="submit">Reject</Button>
                    </Form.Item>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}

export default function Contactus() {
    const userContext = useContext(UserContext);
    const user = userContext.user || {};
    const { ads, type } = useParams();
    const [form] = Form.useForm();
    const [user_role, setUserRole] = useState();
    const [obj, setObj] = useState({});
    const [isReject, setIsReject] = useState(false);

    const auditAdsTagInfo = useRequest(
        body => ({
            url: '/blockBrowser/audit/addressTag/tag',
            method: 'post',
            headers: {
                Authorization: user.token,
            },
            body: JSON.stringify(body),
        }),
        { manual: true, formatResult: r => r },
    );

    useEffect(() => {
        if (type == 'audit') {
            auditAdsTagInfo.run({ id: ads }).then(res => {
                setObj(res.data)
                console.log(res.data, 'data');
                form.setFieldsValue({ ...res.data })
            })
        }
    }, []);

    const addNameTag = useRequest(
        body => ({
            url: '/blockBrowser/feedback/nameAddressTag',
            method: 'post',
            body: JSON.stringify(body),
        }),
        { manual: true, formatResult: r => r },
    );

    const onFinish = (values) => {
        console.log(values, 'values');
        addNameTag.run({ ...values }).then(res => {
            if (res.code == 0) {
                message.info(res.enMsg)
                if (type == 'profile') {
                    window.location.href = `/token/${ads}`
                }
                if (type == 'verified') {
                    window.location.href = `/verifiedAddresses`
                }
            } else {
                message.error(res.enMsg)
            }
            form.resetFields();
        })
    };
    const auditAdsTagRequest = useRequest(
        body => ({
            url: '/blockBrowser/audit/addressTag/audit',
            method: 'post',
            headers: {
                Authorization: user.token,
            },
            body: JSON.stringify(body),
        }),
        { manual: true, formatResult: r => r },
    );

    const handleApprove = () => {
        auditAdsTagRequest.run({ id: obj.id, verified: 1 }).then(res => {
            if (res.code == 0) {
                message.success(res.enMsg)
            } else {
                message.error(res.enMsg)
            }
        })
    }

    return (
        <main id='content' role="main">
            <div className="container">
                <div className="d-flex justify-content-between align-items-center border-bottom py-3" style={{ position: 'relative' }}>
                    {type == 'audit' && <a href="javascript:history.back(-1)"><LeftOutlined style={{ fontSize: '24px', height: '25px', position: 'absolute', left: 0 }} /></a>}
                    <h1 className='h4 mb-0'>Name Tagging</h1>
                    <nav aria-label="breadcrumb">
                        <ol className='breadcrumb small p-0 mb-0'>
                            {/* <li className='breadcrumb-item'><a href="/contactUs">Contact Us</a></li>
                            <li className='breadcrumb-item active'>Name Tagging</li> */}
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="container space-bottom-2 pt-3">
                <div className="row">
                    <div className="col-md-12 mb-4 mb-md-0">
                        <div className="card">
                            <div className="card-header">
                                <h2 className='card-header-title'>Feedback Form</h2>
                            </div>
                            <div className="card-body">
                                <div className="alert alert-info mb-4">
                                    <p className='alert-text font-weight-bold'>Please understand that we can only assist with issues directly related to the block explorer services. Before writing in, please be informed that:</p>
                                    <ol className='alert-text list-xs-space mb-0'>
                                        <li>
                                            <strong>Pending Transaction</strong>
                                            <ul className='list-unstyled'>
                                                <li>We do not <strong>process transactions</strong> and are therefore unable to expedite, cancel or replace them.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>cmp Block Explorer</strong>
                                            <ul className='list-unstyled'>
                                                <li>cmpscan is an independent block explorer unrelated to other service providers (unless stated explicitly otherwise) and is therefore unable to provide a precise response for inquiries that are specific to other service providers.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Knowledge Base</strong>
                                            <ul className='list-unstyled'>
                                                <li>The links on the right might already have the answer to your inquiry. If they don't, perhaps one of the other articles in our Knowledge Base page does.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Community Support</strong>
                                            <ul className='list-unstyled'>
                                                <li>We will never ask for your private keys or passwords. <strong>NEVER send us these.</strong> While we try our best to respond in a timely manner, we often have a backlog of inquiries. Please do not <strong>spam us</strong>.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Wallet / Exchange / Project related issues</strong>
                                            <ul className='list-unstyled'>
                                                <li>Kindly reach out to your wallet service provider, exchanges or project/contract owner for further support as they are in a better position to assist you on the issues related to and from their platforms.</li>
                                            </ul>
                                        </li>
                                    </ol>
                                    <p className='alert-text font-weight-bold'>Please understand that we can only assist with issues directly related to the block explorer services. Before writing in, please be informed that:</p>
                                </div>
                                <p className='mt-0 mb-2'><span id='ContentPlaceHolder1_lblResult'></span></p>
                                {/* <div className="row mb-2">
                                        <div className="col-lg-6">
                                            <label className='mr-2'>Subject <span className='text-danger'>*</span></label>
                                            <select name="ctl00$ContentPlaceHolder1$ddlSubject"
                                                id="ContentPlaceHolder1_ddlSubject"
                                                disabled="disabled"
                                                className='aspNetDisabled custom-select'>
                                                <option value="0" selected='selected'>Please Select Your Message Subject</option>
                                                <option value="1">1. General Inquiry (Non support related)</option>
                                                <option value="2">2. Support / Technical Issue</option>
                                                <option value="5">3. Name Tagging / Label Address</option>
                                                <option value="4">4. Update Token Info (for Contract Owners)</option>
                                                <option value="9">5. API Pro inquiry</option>
                                                <option value="6">6. Priority Support *</option>
                                            </select>
                                        </div>
                                        <div className="col-lg-6 align-self-end mt-3">
                                            <input
                                                name="ctl00$ContentPlaceHolder1$btnReset"
                                                type="submit"
                                                id="ContentPlaceHolder1_btnReset"
                                                className="btn btn-soft-primary cancel"
                                                data-original-title="Click to reset and start over"
                                                value="Start Over"
                                            />
                                        </div>
                                    </div> */}

                                <hr className='hr-space-lg' />
                                <div id="ContentPlaceHolder1_divTokenAlert" className='alert alert-secondary mb-4'>
                                    <div className="media align-items-baseline">
                                        <div className="media-body">
                                            <div className="d-flex align-items-baseline">
                                                <i className='fal fa-lightbulb-on mr-1' />
                                                <p className='alert-text font-weight-bold mb-0'>To tag an address for private use:</p>
                                            </div>
                                            <p className='alert-text mb-2'>Log in to your cmpscan account, navigate to the address page and click on "Update?" located next to the "My Name Tag" section.</p>
                                            <div className="d-flex align-items-baseline">
                                                <i className='fal fa-lightbulb-on mr-1' />
                                                <p className='alert-text font-weight-bold mb-0'>To update token information:</p>
                                            </div>
                                            <p className='alert-text mb-0'>Log in to your cmpscan account and proceed to our token update page: <a href="https://cmpscan.io/tokenupdate" target="_black">https://cmpscan.io/tokenupdate</a></p>
                                            <p className='alert-text mb-0'>If you do not have an account, please sign up here: <a href="https://cmpscan.io/register" target="_black">https://cmpscan.io/register</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div id="ContentPlaceHolder1_maindiv">
                                    <Form layout="vertical" onFinish={onFinish} form={form}>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <Form.Item
                                                    label="Name"
                                                    name={['user_info', 'name']}
                                                    rules={[{ required: true, message: 'Please input your name!' }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </div>
                                            <div className="col-sm-6">
                                                <Form.Item
                                                    label="Email Address"
                                                    name={['user_info', 'email']}
                                                    rules={[{ type: 'email', required: true, message: 'Please enter a valid email address!' }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="row" id='ContentPlaceHolder1_divCompanyInfo'>
                                            <div className="col-sm-6">
                                                <Form.Item
                                                    label="Company Name"
                                                    name={['user_info', 'company_name']}
                                                    rules={[{ required: true, message: 'Please enter your Company Name!' }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </div>
                                            <div className="col-sm-6">
                                                <Form.Item
                                                    label="Company Website"
                                                    name={['user_info', 'company_website']}
                                                    rules={[{ required: true, pattern: '^(http|https)://', message: 'Please enter your company Website!' }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div id='ContentPlaceHolder1_divCompanyUser' className="row">
                                            <div className="col-sm-6">
                                                <Form.Item name={['user_info', 'user_role']} label="Is this an address owner or a user submission?" rules={[{ required: true, message: 'Please select the field' }]}>
                                                    <Select
                                                        placeholder="Please select the field"
                                                        onChange={(value) => setUserRole(value)}
                                                        allowClear
                                                    >
                                                        <Option value={Number("1")}>I'm the address owner</Option>
                                                        <Option value={Number("2")}>I'm a user</Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </div>
                                        {user_role == 2 && (<div id="divAssociateAddress">
                                            <Form.Item name={['user_info', 'project_website']} label="Where did you discover this address?"
                                                rules={[{ required: true, pattern: '^(http|https)://', message: 'The discover address is required' }]}
                                            >
                                                <Input.TextArea />
                                            </Form.Item>
                                        </div>)}

                                        <div id="ContentPlaceHolder1_divNameTag">
                                            <hr className='hr-space-lg' />
                                            <h3 className='h5 mb-4'>Name Tagging</h3>
                                            <div className="mb-4 mt-n1">
                                                <Form.List name="addresses" initialValue={[{}]}>
                                                    {(fields, { add, remove }) => (
                                                        <>
                                                            {fields.map((field) => (
                                                                <Space key={field.key} style={{ width: '100%' }}>
                                                                    <div className="position-relative bg-light border rounded p-3 mb-3">
                                                                        <div className="position-absolute" style={{ top: '5px', right: '15px', borderRadius: '10rem' }}>
                                                                            <MinusCircleOutlined onClick={() => remove(field.name)} />
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-sm-6">
                                                                                <Form.Item
                                                                                    {...field}
                                                                                    label="Smart Contract / Address "
                                                                                    name={[field.name, 'address']}
                                                                                    initialValue={ads}
                                                                                    rules={[{ required: true, message: 'Please enter your address!' }]}
                                                                                >
                                                                                    <Input />
                                                                                </Form.Item>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-sm-4">
                                                                                <Form.Item
                                                                                    {...field}
                                                                                    label="Suggested Name Tag"
                                                                                    name={[field.name, 'tag']}
                                                                                    rules={[{ required: true, message: 'Please enter your name tag!' }]}
                                                                                >
                                                                                    <Input />
                                                                                </Form.Item>
                                                                            </div>
                                                                            <div className="col-sm-4">
                                                                                <Form.Item
                                                                                    {...field}
                                                                                    label="Website"
                                                                                    name={[field.name, 'website']}
                                                                                    rules={[{ required: true, pattern: '^(http|https)://', message: 'Please enter your website!' }]}
                                                                                >
                                                                                    <Input />
                                                                                </Form.Item>
                                                                            </div>
                                                                            <div className="col-sm-4">
                                                                                <Form.Item
                                                                                    {...field}
                                                                                    label="Category Label"
                                                                                    name={[field.name, 'category_label']}
                                                                                >
                                                                                    <Input />
                                                                                </Form.Item>
                                                                            </div>
                                                                        </div>
                                                                        <div className="js-form-message">
                                                                            <Form.Item {...field} name={[field.name, 'description']} label="Short Description"
                                                                                rules={[{ required: true, message: 'Please enter short description' }]}
                                                                            >
                                                                                <Input.TextArea />
                                                                            </Form.Item>
                                                                        </div>
                                                                    </div>
                                                                </Space>
                                                            ))}

                                                            <Form.Item>
                                                                <Button type="link" onClick={() => add()} block>
                                                                    Add more name tagging
                                                                </Button>
                                                            </Form.Item>
                                                        </>
                                                    )}
                                                </Form.List>
                                            </div>
                                        </div>
                                        <div id='ContentPlaceHolder1_divAdditionalComment' className="mb-4">
                                            <hr className='hr-space-lg' />
                                            <Form.Item name="comment" label="Additional Comment">
                                                <Input.TextArea />
                                            </Form.Item>
                                        </div>
                                        {(type == 'verified' || type == 'profile') && <> <div className="row my-3">
                                            <div className="col-md-12">
                                                <div className="text-center">
                                                    <Form.Item>
                                                        <Button type="primary" htmlType="submit">Send Message</Button>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </div>
                                        </>}
                                        <div className="row justify-content-center mb-4">
                                            {obj.verified == 2 && <Button type="primary" danger onClick={() => setIsReject(!isReject)} className="mr-6" disabled={obj.verified == 2}>
                                                Reject
                                            </Button>}
                                            {obj.verified == 1 && <Button type="primary" onClick={() => handleApprove()} disabled={obj.verified == 1}>
                                                Approve
                                            </Button>}
                                            {(obj.verified == 3) && <>
                                                <Button type="primary" danger onClick={() => setIsReject(!isReject)} className="mr-6">
                                                    Reject
                                                </Button>
                                                <Button type="primary" onClick={() => handleApprove()}>
                                                    Approve
                                                </Button>
                                            </>}
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <RejectCon show={isReject} data={obj} user={user} onHide={() => setIsReject(false)} />
                    {/* <div className="col-md-3">
                        <div className="card" id='ContentPlaceHolder1_divUsefulLink'>
                            <div className="card-header">
                                <h2 className='card-header-title'>Helpful Links</h2>
                            </div>
                            <div className="card-body">
                                <h3 className='h6 mb-0'>Wallets:</h3>
                                <div className="mx-2 mb-4">
                                    <ul className='list-group list-group-flush mb-0'>
                                        <li className='list-group-item font-size-base'>
                                            <a href="https://trustwallet.com/" className='link-hover-secondary' target="_blank" rel="nofollow noopener">
                                                <i className='fa fa fa-external-link-alt small mr-1' />TrustWallet (Mobile)
                                            </a>
                                            <sup><span className='badge badge-success ml-1'>Popular</span></sup>
                                        </li>
                                        <li className='list-group-item font-size-base'>
                                            <a href="https://docs.cmp.org/smart-chain/wallet/cmp.html" className='link-hover-secondary' target="_blank" rel="nofollow noopener">
                                                <i className='fa fa fa-external-link-alt small mr-1' />cmp Extension Wallet
                                            </a>
                                        </li>
                                        <li className='list-group-item font-size-base'>
                                            <a href="hhttp://metamask.io/" className='link-hover-secondary' target="_blank" rel="nofollow noopener">
                                                <i className='fa fa fa-external-link-alt small mr-1' />Metamask (Browser web3)
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <h3 className='h6 mb-0'>Others:</h3>
                                <div className="mx-2">
                                    <ul className='list-group list-group-flush mb-0'>
                                        <li className='list-group-item font-size-base'>
                                            <a href="https://docs.cmp.org/smart-chain/wallet/cmp.html" className='link-hover-secondary' target="_blank">
                                                <i className='fa fa fa-external-link-alt small mr-1' />What is cmpscan?
                                            </a>
                                        </li>
                                        <li className='list-group-item font-size-base'>
                                            <a href="hhttp://metamask.io/" className='link-hover-secondary' target="_blank" rel="nofollow noopener">
                                                <i className='fa fa fa-external-link-alt small mr-1' />How to receive and send BNB on cmp
                                            </a>
                                        </li>
                                        <li className='list-group-item font-size-base'>
                                            <a href="https://docs.cmp.org/smart-chain/wallet/cmp.html" className='link-hover-secondary' target="_blank" rel="nofollow noopener">
                                                <i className='fa fa fa-external-link-alt small mr-1' />Connecting MetaMask to cmp Mainnet
                                            </a>
                                        </li>
                                        <li className='list-group-item font-size-base'>
                                            <a href="hhttp://metamask.io/" className='link-hover-secondary' target="_blank" rel="nofollow noopener">
                                                <i className='fa fa fa-external-link-alt small mr-1' />BNB Smart Chain Official Site
                                            </a>
                                        </li>
                                        <li className='list-group-item font-size-base'>
                                            <a href="hhttp://metamask.io/" className='link-hover-secondary' target="_blank">
                                                <i className='fa fa fa-external-link-alt small mr-1' />cmpscan Priority Support
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </main >
    )
}